// TODO: when go "BACK" from panel to search, it refreshes search
// TODO: reviews fetch and details field setup

import {
  MAP_THEME_VISIBILITY_UPDATE,
  MAP_FILTER_VISIBILITY_UPDATE,
  MAP_INITIAL_VISIBLE_LAYERS,
  MAP_FILTERS_UPDATE,
  MAP_THEMES_UPDATE,
  MAP_SEARCH_UPDATE,
  MAP_SEARCH_CLEAR,
  UI_ADD_LOADING_STATE,
  UI_REMOVE_LOADING_STATE,
  UI_UPDATE_SEARCH_TEXT
} from '../mutation-types'
import { VUEAFLET_SET_VIEW } from '@vue-mapp-kit/leaflet/src/store/mutation-types'
import Vue from 'vue'
import store from '@/store'
import router from '@/router'
import { HandleError } from '@/utils/errorHandling'
import {
  SUSTAINABILITY_TILES_URL,
  SUSTAINABILITY_MAP_SERVER_URL,
  SUSTAINABILITY_PEOPLE_LAYER,
  SUSTAINABILITY_NEWS_LAYER,
  SUSTAINABILITY_EVENTS_LAYER
} from '@/utils/constants'
import activitiesApi from '@/api/activities'
import Leaflet from 'leaflet'
import * as esri from 'esri-leaflet'
import map from 'lodash.map'
import reduce from 'lodash.reduce'
import keys from 'lodash.keys'
import indexOf from 'lodash.indexof'
import findKey from 'lodash.findkey'
import find from 'lodash.find'
import forEach from 'lodash.foreach'
import difference from 'lodash.difference'
import values from 'lodash.values'

const state = {
  basemaps: {},
  layers: {
    search: {
      label: 'Search',
      type: 'geo-json',
      visible: true,
      options: {},
      layers: []
    }
  },
  Themes: {
    58: {
      name: 'Water',
      slug: 'water',
      visible: true,
      color: 'blue'
    },
    82: {
      name: 'Energy',
      slug: 'energy',
      visible: true,
      color: 'purple'
    },
    0: {
      name: 'Built Environment',
      slug: 'built-environment',
      visible: true,
      color: 'green'
    },
    8: {
      name: 'Campus Life',
      slug: 'campus-life',
      visible: true,
      color: 'teal'
    },
    23: {
      name: 'Food',
      slug: 'food',
      visible: true,
      color: 'yellow'
    },
    30: {
      name: 'Natural Environment',
      slug: 'natural-environment',
      visible: true,
      color: 'orange'
    },
    37: {
      name: 'Transportation',
      slug: 'transportation',
      visible: true,
      color: 'brown'
    },
    48: {
      name: 'Waste Reduction',
      slug: 'waste-reduction',
      visible: true,
      color: 'dark-grey'
    }
  },
  ThemeOrder: [58, 82, 0, 8, 23, 30, 37, 48],
  // Filter refers to the "Layers" listed in the top nav (AppHeader)
  // TODO: eventually this should be changes to Layers, along with all the getters
  Filters: {
    // ids are actually Theme Ids, sublayerIds more acurately represent the layer itself
    Programs: {
      visible: true,
      slug: 'programs',
      layers: [
        { themeId: 58, layerId: 62 },
        { themeId: 82, layerId: 76 },
        { themeId: 0, layerId: 4 },
        { themeId: 8, layerId: 12 },
        { themeId: 23, layerId: 27 },
        { themeId: 30, layerId: 34 },
        { themeId: 37, layerId: 41 },
        { themeId: 48, layerId: 52 }
      ]
    },
    Projects: {
      visible: true,
      slug: 'projects',
      layers: [
        { themeId: 58, layerId: 60 },
        { themeId: 82, layerId: 78 },
        { themeId: 0, layerId: 2 },
        { themeId: 8, layerId: 10 },
        { themeId: 23, layerId: 25 },
        { themeId: 30, layerId: 32 },
        { themeId: 37, layerId: 39 },
        { themeId: 48, layerId: 50 },
      ]
    },
    Features: {
      visible: false,
      slug: 'green-features',
      layers: [
        { themeId: 58, layerId: 63 },
        { themeId: 82, layerId: 81 },
        { themeId: 0, layerId: 5 },
        { themeId: 8, layerId: 13 },
        { themeId: 23, layerId: 28 },
        { themeId: 30, layerId: 35 },
        { themeId: 37, layerId: 42 },
        { themeId: 48, layerId: 53 },
      ]
    },
    People: {
      visible: false,
      slug: 'people',
      layers: [
        { themeId: undefined, layerId: SUSTAINABILITY_PEOPLE_LAYER }
      ]
    },
    // TODO: re-think this structure because not all "Filter/Layers" are the same anymore
    News: {
      visible: false,
      slug: 'news',
      layers: [
        { themeId: undefined, layerId: SUSTAINABILITY_NEWS_LAYER }
      ]
    },
    Events: {
      visible: false,
      slug: 'events',
      layers: [
        { themeId: undefined, layerId: SUSTAINABILITY_EVENTS_LAYER }
      ]
    }
  },
  FilterOrder: ['Programs', 'Projects', 'Features', 'People', 'Events', 'News'],
  SearchableActivityLayers: [62,76,4,12,27,34,41,52,60,78,2,10,25,32,39,50,108,111,110,67,68,106,80,6,7,14,15,29,36,45,47,43,72,46,54,55,56,116,128],
  initialVisibleLayers: []
}

/**
programs-water, -energy, -built-environment, -campus-life, -food, -natural-environment, -transportation, -waste-reduction
projects-water, -energy, -built-environment, -campus-life, -food, -natural-environment, -transportation, -waste-reduction
*/

const getters = {
  getThemeById: (state, getters) => (id) => {
    return state.Themes[id]
  },
  getVisibleThemes: (state, getters) => {
    return reduce(state.ThemeOrder, (outcome, id) => {
      return (getters.isThemeVisible(id)) ? outcome.concat(id) : outcome
    }, [])
  },
  getVisibleThemesByName: (state, getters) => {
    return reduce(state.ThemeOrder, (outcome, id) => {
      let themeName = getters.getThemeById(id).name
      return (getters.isThemeVisible(id)) ? outcome.concat(themeName) : outcome
    }, [])
  },
  getThemeSlug: (state) => (id) => {
    return state.Themes[id].slug
  },
  getThemeByName: (state, getters) => (name = '') => {
    return find(state.Themes, (theme) => {
      return theme.name === name
    })
  },
  getThemeIdByName: (state, getters) => (name = '') => {
    return findKey(state.Themes, (theme) => {
      return theme.name === name
    })
  },
  isThemeVisible: (state) => (themeId) => {
    return state.Themes[themeId].visible
  },
  getThemesIdArray() { return keys(state.Themes) },
  isFilterVisibile: (state) => (filter = '') => {
    return state.Filters[filter].visible
  },
  getFilterLayers: (state) => (filter = '') => {
    return state.Filters[filter].layers
  },
  getFilterSlug: (state) => (filter = '') => {
    return state.Filters[filter].slug
  },
  getVisibleFilters: (state, getters) => {
    return reduce(state.FilterOrder, (outcome, filterName) => {
      return (state.Filters[filterName].visible) ? outcome.concat(filterName) : outcome
    }, [])
  },
  getVisibleFiltersAsInt: (state, getters) => {
    return map(getters.getVisibleFilters, (filter) => {
      let idx = indexOf(state.FilterOrder, filter)

      if (idx === -1) throw new Error('Filter not found; compare state.FilterOrder with all filters')

      return idx
    })
  },
  getFeatureLayers: (state, getters) => {
    return state.Filters.Features.layers.map(layer => layer.layerId)
  },
  getVisibleFilterFeatures: (state, getters) => {
    let temp = reduce(state.Filters.Features.layers, (outcome, layer) => {
      return getters.isThemeVisible(layer.themeId)
        ? outcome.concat(layer.layerId)
        : outcome
    }, [])

    return temp
  },
  getAllLayers: (state, getters) => {
    let allLayers = reduce(state.FilterOrder, (outcome, filterName) => {
      return outcome.concat(getters.getFilterLayers(filterName))
    }, [])
    
    return reduce(allLayers, (outcome, layer) => {
      return outcome.concat(layer.layerId)
    }, [])
  },
  getAllVisibleLayers: (state, getters) => {
    let allLayers = reduce(getters.getVisibleFilters, (outcome, filterName) => {
      return outcome.concat(getters.getFilterLayers(filterName))
    }, [])

    return reduce(allLayers, (outcome, layerObj) => {
      return (getters.getVisibleThemes.includes(layerObj.themeId)) ? outcome.concat(layerObj.layerId) : outcome
    }, [])
  },
  getSearchFeatureById: (state, getters) => (id) => {
    let {
      layers: {
        search: {
          layers
        } = {}
      } = {}
    } = state

    return find(layers, (layer) => {
      let { properties: { EGISID } = {} } = layer
      return EGISID == id
    })
  },
  getSearchLayers: (state, getters) => {
    let {
      layers: {
        search: {
          layers = []
        } = {}
      } = {}
    } = state

    let allLayers = reduce(layers, (outcome, layer) => {
      let { 
        geometry = {} // does not assign {} if geometry null
      } = layer

      return (geometry !== null && geometry.type)
        ? outcome.concat(layer)
        : outcome
    }, [])

    return allLayers
  },
  getShareParams(state, getters) {
    let params = [
      (getters.getVisibleFilters.length) ? `filters=${getters.getVisibleFilters.toString()}` : 'filters=',
      (getters.getVisibleThemesByName.length) ? `themes=${getters.getVisibleThemesByName.toString()}` : 'themes='
    ]

    return `?${params.filter(param => !!param).join('&')}`
  },
  areInitialLayersLoaded(state, getters) {
    let layersLoaded = []

    forEach(state.initialVisibleLayers, (layer) => {
      if (getters.isCompleted(layer)) layersLoaded.push(layer)
    })

    return (layersLoaded.length === state.initialVisibleLayers.length)
      ? true
      : false
  }
}

const actions = {
  async createMapLayers({ commit, dispatch, getters }) {
    try {
      let masterDynamicLayer = esri.dynamicMapLayer({ url: SUSTAINABILITY_MAP_SERVER_URL })

      // let greenFeatures = esri.dynamicMapLayer({ url: SUSTAINABILITY_MAP_SERVER_URL, layers: getters.getFeatureLayers })

      // let greenFeaturesResponse = await mapsApi.getWhere({ 
      //   service: greenFeatures,
      //   sql: '1=1'
      // })

      // await dispatch('addNamedLayer', {
      //   name: 'GreenFeatures',
      //   layer: greenFeatures,
      //   addToMap: false
      // })

      await dispatch('addNamedLayer', {
        name: 'Master',
        layer: masterDynamicLayer,
        addToMap: false
      })

      commit(UI_ADD_LOADING_STATE, 'masterLayer')

      return
    } catch(e) {
      HandleError(new Error(`[action] createMapLayers: ${e.message}`), e)
    }
  },
  async setInitialMapStateFromParams({ commit, dispatch }) {
    // this action is destructuring the query params in order to set an initial state to the application
    let {
      history: {
        current: {
          query: { filters, themes, lat, lng, zoom }
        } = {}
      } = {}
    } = router

    if (filters !== undefined) {
      const visibleFilters = (filters) ? filters.split(',') : []
      const hideFilters = difference(state.FilterOrder, visibleFilters) // order matters with "difference"  

      map(visibleFilters, (type) => commit(MAP_FILTER_VISIBILITY_UPDATE, { type, visible: true }))
      map(hideFilters, (type) => commit(MAP_FILTER_VISIBILITY_UPDATE, { type, visible: false }))
    }

    if (themes !== undefined) {
      const themeIds = (themes) ? themes.split(',').map(themeName => store.getters.getThemeIdByName(themeName)) : []
      const hideThemes = difference(Object.keys(state.Themes), themeIds) // order matters with "difference"

      map(hideThemes, (themeId) => commit(MAP_THEME_VISIBILITY_UPDATE, { themeId, visible: false }))
    }
    
    // using a @vue-mapp-kit/leaflet mutation to reset the map view
    ;(lat && lng) && commit(VUEAFLET_SET_VIEW, {
      id: 'mainMap',
      config: {
        center: { lat, lng },
        zoom
      }
    })
  },
  async captureInitialVisibleLayers({ commit, getters }) {
    let visibleSlugs = reduce(getters.getVisibleThemes, (outcome, themeId) => {
      getters.isFilterVisibile('Programs') && outcome.push(`${getters.getFilterSlug('Programs')}-${getters.getThemeById(themeId).slug}`)
      getters.isFilterVisibile('Projects') && outcome.push(`${getters.getFilterSlug('Projects')}-${getters.getThemeById(themeId).slug}`)

      return outcome
    }, [])

    getters.isFilterVisibile('Features') && visibleSlugs.push(getters.getFilterSlug('Features'))
    getters.isFilterVisibile('People') && visibleSlugs.push(getters.getFilterSlug('People'))

    commit(MAP_INITIAL_VISIBLE_LAYERS, visibleSlugs)

    return visibleSlugs
  },
  async identifyActivities({ commit, dispatch }, { geometry, mapId }) {
    try {
      // TODO: this identify is fetching all necessary fields, but we are not writing "fetched"
      // when details mounts, it does another fetch
      let features = await activitiesApi.identify({
        geometry,
        layers: store.getters.getAllVisibleLayers.toString() || -1,
        service: store.getters.getNamedLayer('Master'),
        map: store.getters.getMap(mapId)
      })

      return features
    } catch(e) {
      HandleError(new Error(`[action] identifyActivities: ${e}`))
    }
  },
  async identifyActivitiesOnClick({ commit, dispatch }, { geometry, mapId }) {
    try {
      let features = await dispatch('identifyActivities', { geometry, mapId })
      let { result: [activityId] } = features

      // "fetched" property added to inform application that full payload has been fetch
      if (activityId) features.entities[activityId].fetched = true

      await dispatch('updateActivities', features)

      activityId && router.push(store.getters.getActivityRouteConfig(activityId))

      return
    } catch(e) {
      HandleError(new Error(`[action] identifyActivitiesOnClick: ${e}`))
    }
  },
  async searchAllLayers({ commit, dispatch }, text) {
    try {
      // set searchText in store.state.ui.searchText
      commit(UI_UPDATE_SEARCH_TEXT, text)
      commit(UI_ADD_LOADING_STATE, 'searching')
      // enters searchMode, but doesn't exit at end of this action
      // only exits search mode when "cancel search" button is clicked
      commit(UI_ADD_LOADING_STATE, 'searchMode')
      commit(MAP_SEARCH_CLEAR)

      const searchResults = await Promise.all([
        dispatch('searchActivities', text),
        dispatch('searchPeople', text),
        dispatch('searchNews', text),
        dispatch('searchEvents', text)
      ])

      // after promise.all completes, then add results to search layer in store
      if (Array.isArray(searchResults)) {
        searchResults.forEach(({ entities }) => {
          commit(MAP_SEARCH_UPDATE, values(entities))
        })
      }

      dispatch('generalEvent', { category: 'search', action: 'search', label: text })

      commit(UI_REMOVE_LOADING_STATE, 'searching')
    } catch(e) {
      commit(UI_REMOVE_LOADING_STATE, 'searching')
    }
  },
  async toggleLayer({ commit, dispatch, getters }, layer) {
    let visible = !getters.isFilterVisibile(layer)
    let layerSlug = getters.getFilterSlug(layer)

    dispatch('generalEvent', { category: 'layers', action: `${layerSlug}-toggle`, label: `${visible}` })

    commit(MAP_FILTER_VISIBILITY_UPDATE, { type: layer, visible })
  },
  async toggleTheme({ commit, dispatch, getters }, themeId) {
    let visible = !getters.isThemeVisible(themeId)
    let themeSlug = getters.getThemeSlug(themeId)

    dispatch('generalEvent', { category: 'theme', action: `${themeSlug}-toggle`, label: `${visible}` })

    commit(MAP_THEME_VISIBILITY_UPDATE, { themeId, visible })
  }
}

const mutations = {
  [MAP_FILTER_VISIBILITY_UPDATE](state, { type, visible }) {
    Vue.set(state.Filters[type], 'visible', visible)
  },
  [MAP_THEME_VISIBILITY_UPDATE](state, { themeId, visible }) {
    Vue.set(state.Themes[themeId], 'visible', visible)
  },
  [MAP_FILTERS_UPDATE](state, payload) {
    state.Filters = payload
  },
  [MAP_THEMES_UPDATE](state, payload) {
    state.Themes = payload
  },
  [MAP_SEARCH_CLEAR](state) {
    Vue.set(state.layers.search, 'layers', [])
  },
  [MAP_SEARCH_UPDATE](state, payload) {
    let newSearchLayers = state.layers.search.layers.concat(payload)
    Vue.set(state.layers.search, 'layers', newSearchLayers)
  },
  [MAP_INITIAL_VISIBLE_LAYERS](state, slugs) {
    state.initialVisibleLayers = slugs
  }
}

export default {
  state,
  getters,
  actions,
  mutations
}