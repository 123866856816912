<template>
  <div class="dynamic-panel-loading">
    <v-progress-linear v-bind:indeterminate="true" color="grey"></v-progress-linear>
    <loading-block height="190"/>

    <div class="pa-4">
      <loading-block/>
      <loading-block height="10"/>
      <loading-block height="10"/>
      <loading-block height="10"/>
      <loading-block height="10"/>
      <loading-block height="50"/>
      <loading-block height="50" margin-bottom="30"/>

      <loading-block/>
      <loading-block/>
    </div>
  </div>
</template>

<script>
  let DynamicPanelLoading = {
    components: {},
    data() {
      return {}
    }
  }

  export default DynamicPanelLoading
</script>

<style lang="styl">
  .dynamic-panel-loading {
    .progress-linear {
      margin: 0;
    }
  }
</style>
