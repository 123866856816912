export const zoomTolerance = {
  10: 35,
  11: 35,
  12: 35,
  13: 35,
  14: 34.19,
  15: 22.79,
  16: 15.19,
  17: 10.125,
  18: 6.75,
  19: 4.5,
  20: 3,
  21: 2.75
}

/**
 * A function that handles rejecting errors or resolving responses from Esri REST
 *
 * @param {object} error
 * @param {array} featureCollection
 * @param {object} response
 */
export function negotiateResponse(error, featureCollection = {}, response = {}) {
  return new Promise((resolve, reject) => {
    // console.log('Feature Collection: ', featureCollection)
    // console.log('Full Response: ', response)

    if (error) {
      reject(new Error(error.message))
    }

    let { features = [] } = featureCollection
    let { results = [] } = response

    resolve({features, results})
  })
}

const mapsApi = {
  getWhere({ service, sql, layer }) {
    if (!sql || !service) throw new Error('Missing service or sql')

    return new Promise((resolve, reject) => {
      let query = service.query()
      if (layer) query.layer(layer)

      query.where(sql).run((error, featureCollection, response) => {
        negotiateResponse(error, featureCollection, response).then(resolve).catch(reject)
      })
    })
  },
  find({ service, text, fields = '*', layers }) {
    if (!service || !layers) throw new Error('[api] find: missing params')

    let query = service.find()
      .layers(`${layers}`)
      .fields(fields)
      .text(text)

    return new Promise((resolve, reject) => {
      query.run((error, featureCollection, response) => {
        negotiateResponse(error, featureCollection, response).then(resolve).catch(reject)
      })
    })
  },
  /**
   * Get intersection layers using the identify esri.leaflet helper function
   *
   * @param {array} geometry Needs to be formatted as [lng, lat]
   * @param {object} service service/layer to query against, see getters.getNamedLayer
   * @param {object} map use store.getters.getMap() to retrieve map
   * @param {string} layers comma separate list of numbers
   */
  identify({ geometry, service, map, layers }) {
    if (!geometry || !service) throw new Error('[api] identify: missing params')

    let query = service.identify()
      .on(map)
      .tolerance(zoomTolerance[map.getZoom()])
      .at(geometry)

    if (layers) query.layers(`visible:${layers}`)

    query.params.mapExtent = '-110.95675349235535,32.22787736311546,-110.94318151474,32.23559153793882'

    return new Promise((resolve, reject) => {
      query.run((error, featureCollection, response) => {
        negotiateResponse(error, featureCollection, response).then(resolve).catch(reject)
      })
    })
  }
}

export default mapsApi