<template>
  <div class="theme-circles">
    <c-circle v-for="(theme, index) in activeThemes" 
      :color="getColor(theme)" 
      :size="12"
      :key="index"/>
  </div>
</template>

<script>
  import { mapGetters } from 'vuex'
  import map from 'lodash.map'

  let ThemeCircles = {
    props: {
      themes: {
        type: Array,
        default: () => {
          return []
        }
      }
    },

    computed: {
      ...mapGetters(['getThemeByName']),
      activeThemes() {
        return this.themes.filter(theme => {
          return theme && theme !== 'Null'
        })
      }
    },

    methods: {
      getColor(theme) {
        return (this.getThemeByName(theme))
          ? this.getThemeByName(theme).color
          : ''
      }
    }
  }

  export default ThemeCircles
</script>
