import {
  MAP_SEARCH_UPDATE,
  NEWS_UPDATE,
  UI_ADD_LOADING_STATE,
  UI_REMOVE_LOADING_STATE
} from '../mutation-types'
import {
  NEWS_FETCH_DETAILS_FIELDS,
  NEWS_FIELDS_TO_SEARCH,
  SUSTAINABILITY_NEWS_LAYER
} from '@/utils/constants'
import { HandleError } from '@/utils/errorHandling'
import merge from 'lodash.merge'
import values from 'lodash.values'
import uniq from 'lodash.uniq'
import newsApi from '@/api/news'
import { createFriendlyURL } from '@/utils/generalUtils'

const state = {
  entities: {},
  result: []
}

const getters = {
  getNewsById: (state, getters) => (id) => {
    return state.entities[id]
  },
  getNewsPropertiesById: (state, getters) => (id) => {
    return (getters.getNewsById(id))
      ? getters.getNewsById(id).properties
      : {}
  },
  isNewsItemVisible: (state, getters) => (id) => {
    let { SustainabilityTheme = '' } = getters.getNewsPropertiesById(id)

    return getters.getVisibleThemesByName.includes(SustainabilityTheme) &&
      getters.isFilterVisibile('News')
  },
  getNewsRouteConfig: (state, getters) => (id) => {
    if (!getters.getNewsById(id)) throw new Error('News does not exist, cannot route.')

    let title = getters.getNewsPropertiesById(id).Name
    let urlFriendlyTitle = createFriendlyURL(title)

    return {
      name: 'newsDetails',
      params: { id, title: urlFriendlyTitle }
    }
  },
  hasNewsItemBeenFetched: (state, getters) => (id) => {
    return !!getters.getNewsById(id) && getters.getNewsById(id).fetched
  }
}

const actions = {
  async fetchNewsItem({ commit, dispatch, getters }, id) {
    try {
      if (!id || id === 'Null') {
        console.error('[action] fetchNews: id was "Null"')
        return
      }

      if (getters.hasNewsItemBeenFetched(id)) {
        return getters.getNewsById(id)
      }

      commit(UI_ADD_LOADING_STATE, 'loadingNews')

      let news = await newsApi.get(id, NEWS_FETCH_DETAILS_FIELDS)

      // if return a null set, console it out as an error
      if (!news.result.length) console.error(`[action] fetchNews: ${id} was not found in map service`)

      // "fetched" property added to inform application that full payload has been fetch
      if (news.entities[id]) news.entities[id].fetched = true

      commit(NEWS_UPDATE, news)
      commit(UI_REMOVE_LOADING_STATE, 'loadingNews')

      return news.entities[id]
    } catch(e) {
      commit(UI_REMOVE_LOADING_STATE, 'loadingNews')
      HandleError(new Error(`[action] fetchPerson: ${e}`))
    }
  },
  async searchNews({ commit, getters }, text) {
    try {
      commit(UI_ADD_LOADING_STATE, 'searchingNews')

      const news = await newsApi.find({
        service: getters.getNamedLayer('Master'),
        text,
        fields: NEWS_FIELDS_TO_SEARCH,
        layers: SUSTAINABILITY_NEWS_LAYER
      })

      let { entities = {} } = news

      commit(NEWS_UPDATE, news)

      // commit to search happens in store/modules/maps
      // commit(MAP_SEARCH_UPDATE, values(entities))
      commit(UI_REMOVE_LOADING_STATE, 'searchingNews')

      return news
    } catch(e) {
      commit(UI_REMOVE_LOADING_STATE, 'searchingNews')
      HandleError(new Error(`[action] searchingNews: ${e}`))
    }
  }
}

const mutations = {
  [NEWS_UPDATE](state, { result, entities }) {
    state.result = uniq(state.result.concat(result)).slice()
    state.entities = merge({}, state.entities, entities)
  }
}

export default {
  state,
  getters,
  actions,
  mutations
}
