let ListItemDescription = {
  name: 'list-item-description',
  functional: true,
  render(createEl, { data, children }) {
    data.class = { 'list-item__description': true }

    return createEl('p', data, children)
  }
}

export default ListItemDescription