import { mapState, mapGetters } from 'vuex'

export default {
  computed: {
    ...mapState({
      searchLayers: state => state.maps.layers.search.layers,
      measureMode: state => state.measure.mode
    }),
    ...mapGetters([
      'isInProgress',
      'isCompleted',
      'isFilterVisibile',
      'isLoading'
    ]),
    searchMode() {
      return !!this.isInProgress('searchMode')
    },
    peopleOpen() {
      return !!this.isInProgress('peopleOpen')
    },
    showPeopleLayer() {
      return this.isFilterVisibile('People')
    },
    showNewsLayer() {
      return this.isFilterVisibile('News')
    },
    showEventsLayer() {
      return this.isFilterVisibile('Events')
    },
    newsLayerReady() {
      return this.isCompleted('newsLayerReady')
    },
    showMeasureSidebar() {
      return !!this.measureMode
    },
    searchLoading() {
      return this.isLoading('searching')
    },
    activityLoading() {
      return this.isLoading('loadingActivity')
    },
    loadingNews() {
      return this.isLoading('loadingNews')
    },
    loadingEvent() {
      return this.isLoading('loadingEvent')
    },
    personLoading() {
      return this.isLoading('loadingPerson')
    },
    relatedPeopleLoading() {
      return this.isLoading('loadingRelatedPeople')
    },
    relatedActivitiesLoading() {
      return this.isLoading('loadingRelatedActivities')
    }
  }
}