<template>
  <div class="info-panel__wrapper">
    <div class="info-panel__overlay" 
      v-if="innerStatus === 'full' && Mixin_active"
      @click="handleClose()"></div>

    <div class="info-panel" :class="classObject" ref="mainPanel">
      <div class="info-panel__header grey lighten-4 px-3 py-4">
        <slot name="activator">
          <v-btn fab
            @click.stop="handlePanelActivator()"
            class="info-panel__activator">
            <v-icon x-large>keyboard_arrow_up</v-icon>
          </v-btn>
        </slot>

        <v-progress-circular
          indeterminate 
          v-bind:size="50" 
          class="grey--text text--lighten"
          v-if="loading"/>
        <slot name="header" v-else></slot>

        <slot name="back">
          <span @click="handleBack" class="info-panel__back" role="button">Back</span>
          <!-- <v-btn icon 
            class="info-panel__back"
            @click="handleBack()">
            <v-icon class="grey--text text--darken-2">chevron_left</v-icon>
          </v-btn> -->
        </slot>

        <slot name="close">
          <v-btn icon 
            class="info-panel__close"
            @click="handleClose()">
            <v-icon class="grey--text text--darken-2">close</v-icon>
          </v-btn>
        </slot>
      </div>

      <div class="info-panel__content-wrapper" v-if="loading && (innerStatus !== 'preview')">
        <div class="info-panel__content text-xs-center">
          <slot name="loading">
            <v-progress-circular
              indeterminate 
              v-bind:size="100" 
              class="grey--text text--lighten"/>
          </slot>
        </div>
      </div>

      <div class="info-panel__content-wrapper" v-else>
        <div ref="content" class="info-panel__content" :style="contentStyleObject">
          <slot name="content"></slot>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import ExternalBind from '@/mixins/ExternalBind'

  let InfoPanel = {
    mixins: [ExternalBind],
    props: {
      loading: {
        type: Boolean,
        default: false
      },
      status: {
        type: [String, Number],
        default: 'close'
      }
    },
    watch: {
      status(newVal) {
        this.innerStatus = newVal
      },
      Mixin_active(newVal) {
        if (newVal == false) this.innerStatus = this.status
      }
    },
    data() {
      return {
        booted: false,
        innerStatus: 'close' // see delay
      }
    },
    mounted() {
      setTimeout(() => { this.booted = true }, 10)
      this.innerStatus = this.status
    },
    methods: {
      handlePanelActivator() {
        switch(this.innerStatus) {
          case 'preview':
            this.handleHalf()
            break;
          case 'half':
            this.handleFull()
            break;
          case 'full':
            this.handleHalf()
            break;
          default:
            // do something
        }
      },
      handleClose() {
        this.Mixin_active = false
        this.$emit('close')
      },
      handleHalf() {
        this.innerStatus = 'half'
        this.$emit('half')
      },
      handleFull() {
        this.innerStatus = 'full'
        this.$emit('full')
      },
      handleBack() {
        this.$emit('back')
        // this.$router.go(-1)
      },
    },
    computed: {
      classObject() {
        return {
          'info-panel--open': this.Mixin_active,
          'info-panel--close': !this.Mixin_active,
          'info-panel--booted': this.booted,
          'info-panel--preview': this.innerStatus === 'preview',
          'info-panel--half': this.innerStatus === 'half',
          'info-panel--full': this.innerStatus === 'full',
          'info-panel--third': this.innerStatus === 'third',
        }
      },
      contentStyleObject() {
        if (typeof this.innerStatus !== 'number') {
          return {}
        }

        return {
          'height': `${this.innerStatus}px !important`
        }
      }
    }
  }

  export default InfoPanel
</script>

<style lang="stylus" src="./InfoPanel.styl"></style>
