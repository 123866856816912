<template>
  <article :class="classObject">
    <div class="full-page__inner-wrapper">
      <div class="full-page__header">
        <slot name="header"><h2 class="title">Full Page Header</h2></slot>
      </div>

      <div class="full-page__content px-3">
        <slot></slot>
      </div>

      <footer class="full-page__footer pa-5" v-if="$slots['footer']">
        <div class="full-page__footer-content">
          <slot name="footer"></slot>
        </div>
      </footer>
      <slot name="close">
        <v-btn icon @click="handleClose" class="full-page__close"><v-icon>close</v-icon></v-btn>
      </slot>
    </div>
  </article>
</template>

<script>
  import ExternalBind from '@/mixins/ExternalBind'

  let FullPage = {
    components: {},
  
    mixins: [ExternalBind],

    props: {
      fullWidth: Boolean
    },

    data() {
      return {
        booted: false
      }
    },

    mounted() {
      setTimeout(() => { this.booted = true }, 10)
      window.scrollTo(0,0)
    },

    computed: {
      classObject() {
        return {
          'full-page': true,
          'full-page--full-width': this.fullWidth,
          'full-page--open': this.Mixin_active,
          'full-page--close': !this.Mixin_active,
          'full-page--booted': this.booted
        }
      }
    },

    methods: {
      handleClose() {
        this.Mixin_active = false
        this.$emit('close')
      }
    }
  }

  export default FullPage
</script>

<style lang="stylus">
  // @import "@/stylus/variables";
  
  .full-page {
    display: flex;
    flex-direction: column;
    flex: 1;
    z-index: 3;
    position: fixed;
    left: 0;
    right: 0;
    width: 100vw;
    height: 100vh !important;
    background-color: $common-background;
    transition: transform 0.45s ease;
    transform: translateY(0%);
    padding: 48px;

    h1 {
      margin-bottom: 30px;
    }
  }
  
  .full-page--close {
    transform: translateY(0%);
  }
  
  .full-page--open.full-page--booted {
    transform: translateY(calc(-100% + 70px))
  }
  
  .full-page__header {
    text-align: center;
    text-transform: uppercase;
  }
  
  .full-page__content,
  .full-page__footer-content {
    box-sizing: border-box;
    max-width: 650px;
    width: 100%;
    margin: 0 auto;
  }

  .full-page__inner-wrapper
    overflow: auto !important
    overflow-y: scroll !important
    -webkit-overflow-scrolling: touch !important
    height: 100%

  .full-page--full-width .full-page__content {
    max-width: 100%
  }
  
  .full-page__close {
    position: absolute;
    left: 10px;
    top: 10px;
  }
  
  .full-page__footer {
    background-color: lighten($color-grey, 30%);
    
    div, span, p {
      font-weight: bold;
      color: $color-dark-grey;
    }
    
    a {
      color: $color-ua-red;
      text-decoration: none;
    }
  }
  
  @media (max-width: $md-screen-breakpoint) {
    .full-page--open.full-page--booted {
      transform: translateY(calc(-100% + 50px));
    }

    .full-page {
      padding: 72px 30px 30px;
    }

    .full-page__close {
      top: 60px;
      left: 0px;
    }
  }
</style>
