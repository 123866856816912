import Vue from 'vue'
import Router from 'vue-router'
import store from '@/store'

import { UI_UPDATE_EMBED } from '@/store/mutation-types'

const Home = () => import('@/views/Home/Home')
const About = () => import('@/views/About/About')
const Feedback = () => import('@/views/Feedback/Feedback')
const Search = () => import('@/views/Search/Search')
const Help = () => import('@/views/Help/Help')
const DataDashboard = () => import('@/views/DataDashboard/DataDashboard')

const ActivityDetails = () => import('@/views/Activity/ActivityDetails')
const PersonDetails = () => import('@/views/People/PersonDetails')
const EventDetails = () => import('@/views/Events/EventDetails')
const NewsDetails = () => import('@/views/News/NewsDetails')

Vue.use(Router)

export default new Router({
  mode: 'history',
  routes: [
    { 
      path: '/',
      name: 'home',
      component: Home,
      beforeEnter(to, from, next) {
        const { dispatch, state, commit } = store

        let { query: { embed } = {} } = to

        commit(UI_UPDATE_EMBED, !!embed)
        next()
      },
      children: [
        { path: 'about', name: 'about', component: About },
        { path: 'feedback', name: 'feedback', component: Feedback },
        { path: 'search/:searchText', name: 'search', component: Search },
        { path: 'help', name: 'help', component: Help },
        { path: 'data-dashboard', name: 'dataDashboard', component: DataDashboard },
        {
          path: 'activities/:id/:title',
          name: 'activityDetails',
          component: ActivityDetails
        },
        // fall back activity details route
        {
          path: 'activities/:id',
          component: ActivityDetails
        },
        {
          path: 'people/:id/:title',
          name: 'peopleDetails',
          component: PersonDetails
        },
        // fall back people details route
        {
          path: 'people/:id',
          component: PersonDetails
        },
        {
          path: 'events/:id/:title',
          name: 'eventDetails',
          component: EventDetails
        },
        // fall back people details route
        {
          path: 'events/:id',
          component: EventDetails
        },
        {
          path: 'news/:id/:title',
          name: 'newsDetails',
          component: NewsDetails
        },
        // fall back people details route
        {
          path: 'news/:id',
          component: NewsDetails
        }
      ]
    }
  ]
})
