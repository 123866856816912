<template>
  <dynamic-panel v-model="DynamicPanelContainerMixin_active"
    info-panel-status="preview"
    key-override="measure"
    @close="handleClose"
    class="measure-sidebar">
    <span slot="back"></span>
    <span slot="panel-activator"></span>

    <template v-if="DynamicPanelContainerMixin_active">
      <div slot="panel-header" class="hidden-md-and-up">
        <p class="mt-3 mb-0">Click anywhere on the map to begin and double click on the map to finish.</p>
        <div class="measure-sidebar__info-panel-header" v-if="isMeasurementArea">
          <div class="measure-sidebar__wrapper">
            <div class="measure-sidebar__measurement">
              <strong>Area: </strong> {{ getAreaDisplay }}
            </div>
            <v-select class="measure-sidebar__units"
              :items="areaUnits" 
              v-model="unit"/>
          </div>

          <div class="measure-sidebar__wrapper">
            <div class="measure-sidebar__measurement">
              <strong>Perimmeter: </strong>{{ getLengthDisplay }} Feet
            </div>
          </div>
        </div>

        <div class="measure-sidebar__info-panel-header" v-else>
          <div class="measure-sidebar__wrapper">
            <div class="measure-sidebar__measurement">
              <strong>Distance: </strong>{{ getLengthDisplay }}
            </div>
            <v-select class="measure-sidebar__units"
              :items="pathUnits" 
              v-model="unit"/>
          </div>
        </div>
      </div>

      <div class="measure-sidebar__content">
        <h3>{{ getTitle }}</h3>

        <ul>
          <list-item>
            <list-item-content>
              <list-item-description>
                <p>Click anywhere on the map to begin and double click on the map to finish.</p>
                <template v-if="isMeasurementArea">
                  <div class="measure-sidebar__wrapper">
                    <div class="measure-sidebar__measurement">
                      <strong>Area: </strong> {{ getAreaDisplay }}
                    </div>
                    <v-select class="measure-sidebar__units"
                      :items="areaUnits" 
                      v-model="unit"/>
                  </div>

                  <div class="measure-sidebar__wrapper">
                    <div class="measure-sidebar__measurement">
                      <strong>Perimmeter: </strong>{{ getLengthDisplay }} Feet
                    </div>
                  </div>
                </template>

                <template v-else>
                  <div class="measure-sidebar__wrapper">
                    <div class="measure-sidebar__measurement">
                      <strong>Distance: </strong>{{ getLengthDisplay }}
                    </div>
                    <v-select class="measure-sidebar__units"
                      :items="pathUnits" 
                      v-model="unit"/>
                  </div>
                </template>
              </list-item-description>
              
            </list-item-content>
          </list-item>
        </ul>
      </div>
    </template>

  </dynamic-panel>
</template>

<script>
  import MasterList from '@/components/_lists/MasterList/MasterList'
  import DynamicPanelContainerMixin from '@/mixins/DynamicPanelContainerMixin'
  import DetailsSideBar from '@/mixins/DetailsSideBar'
  import { mapState, mapGetters, mapActions } from 'vuex'

  let MeasureSidebar = {
    name: 'measure-sidebar',
    
    components: {
      MasterList
    },

    mixins: [DynamicPanelContainerMixin, DetailsSideBar],

    data() {
      return {
        pathUnits: ['feet', 'meters', 'miles', 'kilometers'],
        areaUnits: ['acres', 'hectares', 'sqfeet', 'sqmeters', 'sqmiles']
      }
    },

    computed: {
      ...mapState({
        measureData: state => state.measure.data,
        measureMode: state => state.measure.mode,
        measureUnit: state => state.measure.unit
      }),
      ...mapGetters(['isMeasurementArea']),
      getMeasureData() {
        return JSON.stringify(this.measureData)
      },
      getTitle() {
        return this.isMeasurementArea
          ? 'Area Measurement'
          : 'Path Measurement'
      },
      getLengthDisplay() {
        let { lengthDisplay = '' } = this.measureData

        let measure = lengthDisplay.split(' ')

        return (measure.length) ? measure[0] : ''
      },
      getAreaDisplay() {
        let { areaDisplay = '' } = this.measureData

        let measure = areaDisplay.split(' ')

        return (measure.length) ? measure[0] : ''
      },
      unit: {
        get(val) {
          return this.measureUnit
        },
        set(val) {
          this.setMeasurementUnit(val)
        }
      }
    },

    methods: {
      ...mapActions([
        'setMeasurementUnit',
        'clearMeasurement'
      ]),
      handleClose() {
        this.DynamicPanelContainerMixin_handleClose()

        setTimeout(() => {
          this.clearMeasurement()
        }, (1000 * 0.40))
      }
    }
  }

  export default MeasureSidebar
</script>

<style lang="styl">
  // @import "@/stylus/variables";

  .measure-sidebar {
    .dynamic-panel__info-panel-header {
      
    }
  }

  .measure-sidebar__wrapper {
    position: relative;
    display: flex;
    align-items: center;
    margin-bottom: 15px;
    margin-right: 15px;
  }

  .measure-sidebar__units {
    margin-left: 15px;
    padding: 0 !important;

    .input-group__details {
      height: 1px !important;
      min-height: auto;
    }
  }

  .measure-sidebar__content {
    padding: 15px;

    h3 {
      margin-bottom: 15px;
    }

    ul {
      padding: 0;
      margin: 0;

      li {
        cursor: default;
      }
    }
  }

  .measure-sidebar__info-panel-header {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
  }

  @media(max-width: $xs-screen-breakpoint) {
    .measure-sidebar__info-panel-header {
      flex-direction: column;
    }
  }
</style>
