<template>
  <nav class="layer-toggles">
    <span class="layer-toggles__header">Layers:</span>
    <v-btn-toggle :input-value="getVisibleFiltersAsInt" @change="() => {}" multiple>
      <v-btn 
        v-for="layer in filters" 
        :key="layer" 
        @click="toggleLayer(layer)"
        round
        class="btn--chip">
        {{ layer }}
      </v-btn>
    </v-btn-toggle>
  </nav>
</template>

<script>
  import { mapGetters, mapState, mapActions } from 'vuex'
  import DisplayManager from '@/mixins/DisplayManager'

  let LayerToggles = {
    mixins: [DisplayManager],

    computed: {
      ...mapState({
        filters: state => state.maps.FilterOrder
      }),
      ...mapGetters(['getVisibleFiltersAsInt'])
    },

    methods: {
      ...mapActions(['toggleLayer'])
    }
  }

  export default LayerToggles
</script>

<style lang="stylus">
  // @import "@/stylus/variables"
  
  .layer-toggles {
    .btn-toggle {
      background: transparent !important;
      box-shadow: none;
    }
    
    .layer-toggles__header {
      text-transform: uppercase;
      font-size: 12px;
      margin-right: 10px;
      color: $color-dark-grey;
    }
  }
</style>