let LoadingBlock = {
  name: 'loading-block',
  functional: true,
  // this will be converted into 20px width and height
  props: {
    height: [Number, String],
    marginBottom: [Number, String]
  },

  render(createEl, { data, props, children }) {
    let { height, marginBottom } = props

    data.class = {
      'loading-block': true
    }

    data.style = {
      height: `${height}px`,
      marginBottom: `${marginBottom}px`
    }

    return createEl('div', data)
  }
}

export default LoadingBlock