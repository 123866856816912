<template>
  <list-item class="person" 
    @click="$router.push(getPersonRouteConfig(info.PeopleID))"
    :key="info.PeopleID">
    <list-item-content>
      <list-item-title>{{ info.Name }}</list-item-title>
      <list-item-subtitle>
        <span class="person__dept" v-if="info.AffDept && info.AffDept != 'Null'">{{ info.AffDept }}</span>
      </list-item-subtitle>
      <div class="list-item__attributes">
        <pill>People</pill>
        <theme-circles :themes="getPersonThemes(info.PeopleID)"/>
      </div>
    </list-item-content>
    
    <list-item-avatar circle>
      <div class="person__headshot" 
        :style="`background-image: url(${getPhoto})`"
        v-if="getPhoto"></div>
      <v-icon x-large v-else>account_circle</v-icon>
      <div class="person__circles">
        
      </div>
    </list-item-avatar>
  </list-item>
</template>

<script>
  import ThemeCircles from '@/components/ThemeCircles/ThemeCircles'
  import { PEOPLE_PHOTO_BASE_URL } from '@/utils/constants'
  import { mapGetters } from 'vuex'

  let PersonItem = {
    components: {
      ThemeCircles
    },

    props: {
      info: {
        type: Object,
        default: () => {
          // { Name, Title, Department, EGISID, SustainabilityTheme, SustainabilityTheme2, Photo }
          return {}
        }
      }
    },

    computed: {
      ...mapGetters([
        'getPersonRouteConfig',
        'getPersonThemes'
      ]),
      getPhoto() {
        let { Photo } = this.info

        if (!Photo || Photo === null) return false

        return `${PEOPLE_PHOTO_BASE_URL}/${Photo}`
      }
    }
  }

  export default PersonItem
</script>

<style lang="styl">
  .person {
    align-items: center; 
    .list-item__description {
      p:last-child {
        margin-bottom: 0;
        padding-bottom: 0;
      }
    }

    .theme-circles {
      line-height: 1em;
    }
  }

  .person__headshot {
    height: 65px;
    width: 65px;
    border-radius: 50%;
    background-size: cover;
    background-position: center center;
  }

  .person__circles {
    text-align: center;
    line-height: 1em;
  }

  .person__dept {
    font-size: 12px;
    margin-bottom: 10px;
    line-height: 1em;
  }
</style>

