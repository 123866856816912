<template>
  <list-item class="activity-item" 
    @click="$router.push(getActivityRouteConfig(activity.EGISID))"
    :key="activity.EGISID">
    <list-item-content>
      <list-item-title>{{ activity.Name }}</list-item-title>
      <list-item-description>{{ activity.Summary }}</list-item-description>

      <div class="list-item__attributes">
        <pill>{{ getSusType(activity.SustainabilityType) }}</pill>
        <theme-circles :themes="[activity.SustainabilityTheme, activity.SustainabilityTheme2]"/>
      </div>
    </list-item-content>
  </list-item>
</template>

<script>
  import ThemeCircles from '@/components/ThemeCircles/ThemeCircles'
  import { mapGetters } from 'vuex'

  let ActivityItem = {
    components: {
      ThemeCircles
    },

    props: {
      activity: {
        type: Object,
        required: true,
        default: () => {
          return {}
        }
      }
    },

    data() {
      return {}
    },

    computed: {
      ...mapGetters(['getActivityRouteConfig'])
    },

    methods: {
      getSusType(type) {
        if (!type || type === 'Null') return ''

        return (type.trim().toLowerCase() === 'green feature')
          ? 'Feature'
          : type
      }
    }
  }

  export default ActivityItem
</script>
