import Vue from 'vue'
import {
  FEATURES_UPDATE,
  FEATURES_UPDATE_RELATED_PEOPLE,
  PEOPLE_UPDATE,
  MAP_SEARCH_UPDATE,
  UI_ADD_LOADING_STATE,
  UI_REMOVE_LOADING_STATE
} from '../mutation-types'
import activitiesApi from '@/api/activities'
import relatedApi from '@/api/related'
import store from '@/store'
import { HandleError } from '@/utils/errorHandling'
import {
  ACTIVITY_FIELDS_TO_SEARCH,
  ACTIVITY_FETCH_DETAIL_FIELDS
} from '@/utils/constants'
import reduce from 'lodash.reduce'
import range from 'lodash.range'
import merge from 'lodash.merge'
import uniq from 'lodash.uniq'
import map from 'lodash.map'
import values from 'lodash.values'
import { createFriendlyURL } from '@/utils/generalUtils'


const state = {
  entities: {},
  result: []
}

const getters = {
  getActivityById: (state) => (id) => {
    return state.entities[id]
  },
  getActivityPropertiesById: (state, getters) => (id) => {
    return (getters.getActivityById(id))
      ? getters.getActivityById(id).properties
      : {}
  },
  // TODO: should change this to ID when all features are loaded in store
  getActivityThemes: (state, getters) => (properties) => {
    // const properties = getters.getActivityPropertiesById(id)

    return reduce(range(0, 5), (outcome, num) => {
      let theme = properties[`SustainabilityTheme${(num) ? num : ''}`]

      return (theme && theme !== 'Null')
        ? outcome.concat(theme)
        : outcome 
    }, [])
  },
  hasActivityBeenFetched: (state, getters) => (id) => {
    return !!getters.getActivityById(id) && getters.getActivityById(id).fetched
  },
  getActivityRouteConfig: (state, getters) => (id) => {
    if (!getters.getActivityById(id)) throw new Error('Activity does not exist, cannot route.')

    let title = getters.getActivityPropertiesById(id).Name || getters.getActivityPropertiesById(id).Summary
    let urlFriendlyTitle = createFriendlyURL(title)

    return {
      name: 'activityDetails',
      params: { id, title: urlFriendlyTitle }
    }
  },
  // TODO: might be able to use this in the ProgramProjectsContainer
  isActivityVisible: (state, getters) => (id) => {
    let { 
      SustainabilityTheme = '',
      SustainabilityType = ''
    } = getters.getActivityPropertiesById(id)

    return getters.getVisibleThemesByName.includes(SustainabilityTheme) 
      && getters.getVisibleFilters.includes(`${SustainabilityType}s`)
  }
}

const actions = {
  updateActivities({ commit, dispatch }, payload) {
    // payload should be { result, entities }
    commit(FEATURES_UPDATE, payload)
  },
  async getPeopleByActivity({ commit, dispatch }, id) {
    try {
      let { relatedPeople = [] } = store.getters.getActivityById(id)

      if (relatedPeople.length) {
        return relatedPeople
      }

      commit(UI_ADD_LOADING_STATE, 'loadingRelatedPeople')

      let people = await relatedApi.getPeopleByActivity(id)

      let { result, entities } = people

      // TODO: need to do a more intelligent merge to not overwrite geometry
      if (result[0]) entities[result[0]].geometry = undefined

      commit(UI_REMOVE_LOADING_STATE, 'loadingRelatedPeople')
      commit(PEOPLE_UPDATE, people)
      commit(FEATURES_UPDATE_RELATED_PEOPLE, { id, people: people.result })
      return people.result
    } catch(e) {
      commit(UI_REMOVE_LOADING_STATE, 'loadingRelatedPeople')
      HandleError(new Error(`[action] getPeopleByActivity: ${e}`))
    }
  },
  async fetchActivity({ commit, getters }, id) {
    try {
      // if full details have been "fetched", or we are currently in "searchMode"
      // return what is already in the store
      if (store.getters.hasActivityBeenFetched(id) || getters.isInProgress('searchMode')) {
        return store.getters.getActivityById(id)
      }

      commit(UI_ADD_LOADING_STATE, 'loadingActivity')

      let activity = await activitiesApi.get(id, ACTIVITY_FETCH_DETAIL_FIELDS)

      // if no activity was found with activitiesApi.get
      // doing a "search" which will include all the green features as well
      if (!activity.result.length) {
        activity = await activitiesApi.find({
          service: getters.getNamedLayer('Master'),
          text: id,
          fields: ['EGISID'],
          layers: store.state.maps.SearchableActivityLayers.toString()
        })
      }

      if (!activity.result.length) {
        console.error(`[action] fetchActivity: ${id} was not found in map service`)
      }

      // "fetched" property added to inform application that full payload has been fetch
      if (activity.entities[id]) activity.entities[id].fetched = true

      commit(FEATURES_UPDATE, activity)
      commit(UI_REMOVE_LOADING_STATE, 'loadingActivity')

      return activity.entities[id]
    } catch(e) {
      commit(UI_REMOVE_LOADING_STATE, 'loadingActivity')
      HandleError(new Error(`[action] fetchActivity: ${e}`))
    }
  },
  async searchActivities({ commit, getters }, text) {
    try {
      commit(UI_ADD_LOADING_STATE, 'searchingActivities')

      const features = await activitiesApi.find({
        service: getters.getNamedLayer('Master'),
        text,
        fields: ACTIVITY_FIELDS_TO_SEARCH,
        layers: store.state.maps.SearchableActivityLayers.toString()
      })

      let { entities = {} } = features

      commit(FEATURES_UPDATE, features)

      // commit to search happens in store/modules/maps
      // commit(MAP_SEARCH_UPDATE, values(entities))
      commit(UI_REMOVE_LOADING_STATE, 'searchingActivities')

      return features
    } catch(e) {
      commit(UI_REMOVE_LOADING_STATE, 'searchingActivities')
      HandleError(new Error(`[action] searchActivities: ${e}`))
    }
  }
}

const mutations = {
  [FEATURES_UPDATE](state, { result, entities }) {
    state.result = uniq(state.result.concat(result)).slice()
    state.entities = merge({}, state.entities, entities)
  },
  [FEATURES_UPDATE_RELATED_PEOPLE](state, payload) {
    let { id, people } = payload

    if (!Array.isArray(people)) HandleError(new Error(`[mutations] FEATURES_UPDATE_RELATED_PEOPLE: people is not an array`))

    let newPeople = uniq(state.entities[id].relatedPeople.concat(
      map(people, id => Number(id))
    ))

    Vue.set(state.entities[id], 'relatedPeople', newPeople)
  }
}

export default {
  state,
  getters,
  actions,
  mutations
}