import mapsApi, { negotiateResponse } from '@/api/maps'
import reduce from 'lodash.reduce'
import clonedeep from 'lodash.clonedeep'
import moment from 'moment'
import { query as esriQuery } from 'esri-leaflet'
import { 
  SUSTAINABILITY_MAP_SERVER_URL,
  SUSTAINABILITY_NEWS_LAYER
} from '@/utils/constants'

// TODO: Why are there two X,Ys?
export function normalizeNewsCollection(collection = [], fullResult) {
  try {
    let result = []
    let innerCollection = clonedeep(collection)

    let entities = reduce(innerCollection, (outcome, feature) => {
      let {
        properties,
        properties: {
          Field_pub_date,
          ID,
          Title,
          OBJECTID
        } = {}
      } = feature

      if (!ID) console.error('[normalizeNewsCollection]: News item in collection did not contain ID')

      const NewsID = String(ID || OBJECTID)

      feature.properties = {
        ...properties,
        isNews: true,
        Name: Title,
        NewsID,
        SortDate: moment(Field_pub_date, 'MM/DD/YYYY').format(),
        Status: 'active',
        SustainabilityType: 'News'
      }

      outcome[NewsID] = Object.assign({}, feature)
      result.push(NewsID)

      return outcome
    }, {})

    return { result, entities }
  } catch(e) {
    throw new Error(`normalizeNewsCollection: ${e}`)
  }
}

const newsApi = {
  find({ service, text, fields = '*', layers }) {
    return new Promise((resolve, reject) => {
      mapsApi.find({ service, text, fields, layers })
        .then(({ features, results }) => resolve(normalizeNewsCollection(features, results)))
        .catch(reject)
    })
  },

  get(id) {
    if (!id) throw new Error('[api] event get: no id parameter')
    let query = esriQuery({ url: `${SUSTAINABILITY_MAP_SERVER_URL}/${SUSTAINABILITY_NEWS_LAYER}` })

    return new Promise((resolve, reject) => {
      query.where(`ID=${id}`).run((error, featureCollection, response) => {
        negotiateResponse(error, featureCollection, response)
          .then(({ features, results }) => resolve(normalizeNewsCollection(features, results)))
          .catch(reject)
      })
    })
  }
}

export default newsApi
