import axios from 'axios'

function encodeAppName (app) {
  const t = String(new Date().getTime()).split('').reverse()
  const a = String(app).slice(0, 3).split('').map(v => v.charCodeAt(0))
  const k = [0, 5, 10].reduce((o, v, i) => { o.splice(v, 0, a[i]); return o }, t)
  return k.join(`.`)
}

const notificationsApi = {
  async post({ replyToName, replyTo, subject, body }) {
    if (
      !replyTo ||
      !subject ||
      !body
    ) {
      throw new Error('[emailApi.post] missing required params')
    }

    const message = `
      From: ${replyToName} (${replyTo})
      <br>
      <br>
      ${body}
      <br>
      <br>
      <br>
      (you can reply directly to this email to respond to requester)
    `

    return axios.post(`${process.env.VUE_APP_MAILER_API_URL}/ses?app=sustainabilitymap`, {
      replyTo,
      replyToName,
      destination: process.env.VUE_APP_FEEDBACK_DESTINATION || 'CCT-WebDev@arizona.edu',
      subject,
      message
    }, {
      headers: {
        authorization: encodeAppName('sustainabilitymap')
      }
    })
  }
}

export default notificationsApi