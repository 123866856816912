import Vue from 'vue'
import { HandleError } from '@/utils/errorHandling'

const actions = {
  async generalEvent({}, payload) {
    try {
      let { category, action, label, value } = payload

      if (!category) throw new Error('General Event requires a category')

      Vue.gtm.trackEvent({
        event: 'general-event',
        category, // dataLayer.GACategory
        action, // dataLayer.GAAction
        label: (typeof label === 'object') ? JSON.stringify(label) : label, // dataLayer.GALabel
        value, // dataLayer.GAValue
      })
    } catch(e) {
      let { message } = e
      HandleError(message, e)
    }
  },
}

export default { actions }
