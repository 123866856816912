import Raven from 'raven-js'
import isEmpty from 'lodash.isempty'

export function HandleError(error, extra = {}) {
  // error argument can be Error object or just message/string
  let convertExtra = (typeof extra === 'string')
    ? { message: extra }
    : extra
    
  let spreadError = Object.assign(new Error(error), { ...convertExtra })

  let extraParams = !isEmpty(convertExtra)
    ? { extra: convertExtra }
    : {}

  Raven.captureException(spreadError, extraParams)
  console.error(spreadError)
  throw spreadError
}
