<template>
  <v-menu 
    offset-y 
    :nudge-top="50" 
    left
    :min-width="150"
    content-class="stoplight-menu">
    <v-btn icon slot="activator">
      <v-icon>more_vert</v-icon>
    </v-btn>
    <v-list role="navigation" aria-label="More Options Menu">
      <v-list-tile v-for="item in topItems" :key="item.title" :to="{ name: item.to }" class="stoplight-menu--top" tag="li">
        <v-list-tile-title>{{ item.title }}</v-list-tile-title>
      </v-list-tile>

      <v-divider/>

      <v-list-tile v-for="item in bottomItems" :key="item.title" :to="{ name: item.to }" tag="li">
        <v-list-tile-title>{{ item.title }}</v-list-tile-title>
      </v-list-tile>
    </v-list>
  </v-menu>
</template>

<script>
  let StoplightMenu = {
    components: {},

    data() {
      return {
        topItems: [
          { title: 'Map', to: 'home' }
        ],
        bottomItems: [
          { title: 'Data Dashboard', to: 'dataDashboard' },
          { title: 'Help', to: 'help' },
          { title: 'Feedback', to: 'feedback' },
          { title: 'About', to: 'about' }
        ]
      }
    }

  }

  export default StoplightMenu
</script>

<style lang="stylus">
  // @import "@/stylus/variables";
  
  .stoplight-menu {
    .list {
      background-color: $color-dark-grey !important;
    }
    
    .divider {
      background-color: rgba($color-light-grey, 0.1) !important;
    }

    .list__tile__title {
      font-size: 16px;
      color: $color-light-grey;
    }
  }
  
  .stoplight-menu--top {
    .list__tile__title {
      font-weight: bold !important;
    }
  }
</style>