import Vue from 'vue'
import router from '@/router'
import VueGtm from 'vue-gtm'

Vue.use(VueGtm, {
  id: 'GTM-M6STQV3', // Your GTM ID
  enabled: process.env.NODE_ENV === 'production', // defaults to true. Plugin can be disabled by setting this to false for Ex: enabled: !!GDPR_Cookie (optional)
  debug: process.env.NODE_ENV !== 'production', // Whether or not display console logs debugs (optional)
  vueRouter: router // Pass the router instance to automatically sync with router (optional)
  //ignoredViews: ['homepage'] // If router, you can exclude some routes name (case insensitive) (optional)
})