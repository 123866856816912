<template>
  <div class="map-controls">
    <div class="map-controls__group elevation-5">
      <v-btn icon @click="findMyLocation">
        <v-progress-circular indeterminate class="grey--text text--lighten" v-if="locating"></v-progress-circular>
        <v-icon medium v-else>my_location</v-icon>
      </v-btn>

      <v-btn @click="handleShare" icon>
        <v-icon medium>share</v-icon>
      </v-btn>
    </div>

    <div class="map-controls__group elevation-5">
      <v-btn icon 
        :disabled="measureMode === measurePath"
        @click="setMeasurementMode(measureArea)" 
        :class="getMeasureClasses(measureArea)">
        <svg class="map-controls__path-icon" viewBox="0 0 24 24" v-if="measureMode !== measureArea">
            <path fill="#000000" d="M2,2V8H4.28L5.57,16H4V22H10V20.06L15,20.05V22H21V16H19.17L20,9H22V3H16V6.53L14.8,8H9.59L8,5.82V2M4,4H6V6H4M18,5H20V7H18M6.31,8H7.11L9,10.59V14H15V10.91L16.57,9H18L17.16,16H15V18.06H10V16H7.6M11,10H13V12H11M6,18H8V20H6M17,18H19V20H17" />
        </svg>
        <v-icon medium v-else>layers_clear</v-icon>
      </v-btn>
      <v-btn icon 
        :disabled="measureMode === measureArea"
        @click="setMeasurementMode(measurePath)" 
        :class="getMeasureClasses(measurePath)">
        <svg class="map-controls__path-icon" viewBox="0 0 24 24" v-if="measureMode !== measurePath">
          <path fill="#000000" d="M16,2V8H17.08L14.95,13H14.26L12,9.97V5H6V11H6.91L4.88,16H2V22H8V16H7.04L9.07,11H10.27L12,13.32V19H18V13H17.12L19.25,8H22V2M18,4H20V6H18M8,7H10V9H8M14,15H16V17H14M4,18H6V20H4" />
        </svg>
        <v-icon medium v-else>layers_clear</v-icon>
      </v-btn>
    </div>

    <div class="map-controls__group elevation-5">
      <v-btn icon @click="zoomIn">
        <v-icon medium>add</v-icon>
      </v-btn>
      <v-btn icon @click="zoomOut">
        <v-icon medium>remove</v-icon>
      </v-btn>
    </div>
  </div>
</template>

<script>
  import { mapState, mapActions, mapGetters, mapMutations } from 'vuex'

  let MapControls = {
    components: {},

    data() {
      return {
        locating: false,
        mapId: 'mainMap',
        measureArea: 'measureArea',
        measurePath: 'measurePath'
      }
    },

    methods: {
      ...mapActions([
        'openDialog',
        'setMeasurementMode',
        'clearMeasurement',
        'generalEvent'
      ]),
      handleCaptureEvent(label = '') {
        this.generalEvent({ category: 'map-controls', action: 'click', label })
      },
      zoomIn() {
        let map = this.getMap(this.mapId)
        let currentZoom = map.getZoom()
        let newZoom = currentZoom + 1

        if (newZoom <= map.getMaxZoom()) map.setZoom(newZoom)
        this.handleCaptureEvent('zoomIn')
      },
      zoomOut() {
        let map = this.getMap(this.mapId)
        let currentZoom = map.getZoom()
        let newZoom = currentZoom - 1

        if (newZoom >= map.getMinZoom()) map.setZoom(newZoom)
        this.handleCaptureEvent('zoomOut')
      },
      findMyLocation() {
        let vm = this

        vm.locating = true

        this.$mappKitBus.$on(`map-${this.mapId}-locationfound`, () => { vm.locating = false })
        this.$mappKitBus.$on(`map-${this.mapId}-locationerror`, (e) => {
          alert(`${e.message} Please change your browser settings to Allow Geolocation Services.`)
          vm.locating = false
        })

        let map = this.getMap(this.mapId)
        map.locate({ setView: true })
        this.handleCaptureEvent('findMyLocation')
      },
      handleShare() {
        this.openDialog({ dialog: 'ShareMapDialog' })
        this.handleCaptureEvent('share')
      },
      getMeasureClasses(mode) {
        return {
          'map-controls--active': this.measureMode === mode
        }
      }
    },

    computed: {
      ...mapState({
        measureMode: state => state.measure.mode
      }),
      ...mapGetters(['getMap', 'isInProgress'])
    }
  }

  export default MapControls
</script>

<style lang="stylus">
  // @import "@/stylus/variables";
  
  // TODO: should these be smaller on phone?
  .map-controls {
    position: absolute;
    right: -45px;
    top: 10px;
    transition: left 0.4s ease;
    
    .btn {
      margin: 3px
    }
    
    .btn .btn__content .icon {
      color: $color-dark-grey !important;
      font-size: 22px !important;
    }
    
    .btn--icon {
      width: 28px;
      height: 28px;
    }
    
    [data-tooltip]:before {
      padding: 10px;
      width: 200px;
      height: auto;
      white-space: normal;
      background-color: $color-grey;
      font-size: 14px;
    }

    .map-controls__group {
      display: flex;
      flex-direction: column;
      background: #fff;
      margin-bottom: 10px;
    }
    
    .map-controls--active {
      background-color: $color-light-grey;
      box-shadow: 0 0 0 rgba($color-ua-red, 0.75);
      animation: pulse 2s infinite;
    }

    @keyframes pulse {
      0% { box-shadow: 0 0 0 0 rgba($color-ua-red, 0.75); }
      70% { box-shadow: 0 0 0 10px rgba($color-ua-red, 0); }
      100% { box-shadow: 0 0 0 0 rgba($color-ua-red, 0); }
    }
  }
  
  .btn--disabled {
    .map-controls__path-icon {
      opacity: .5;
    }
  }

  .map-controls__path-icon {
    width: 22px;
    height: 22px;
    
    path {
      fill: $color-dark-grey;
    }
  }
</style>
