import { UI_UPDATE_DETAILS_SIDEBAR_OPEN } from '@/store/mutation-types'
import store from '@/store'

export default {
  watch: {
    DynamicPanelContainerMixin_active(val) {
      store.commit(UI_UPDATE_DETAILS_SIDEBAR_OPEN, val)
    }
  }
}
