let Pill = {
  name: 'pill',
  functional: true,
  // this will be converted into 20px width and height
  props: {
    color: {
      type: String,
      default: 'grey'
    },
    outline: Boolean,
    large: Boolean
  },

  render(createEl, { data, props, children }) {
    data.class = {
      'pill': true,
      [`pill--${props.color}`]: true,
      'pill--outline': props.outline,
      'pill-large': props.large
    }

    return createEl('span', data, children)
  }
}

export default Pill