import Vue from 'vue'
import {
  DIALOG_ACTIVE_UPDATE,
  DIALOG_ACTIVE_CLEAR
} from '../mutation-types'

const state = {
  dialog: '',
  props: {}
}

const getters = {}

const actions = {
  openDialog({ commit }, payload) {
    if (!payload && !payload.dialog) throw new Error('[action] openDialog: payload not correct shape')

    let promiseReturn;

    if (payload.dialog === 'PromptDialog') {
      promiseReturn = new Promise((resolve, reject) => {
        payload.props = Object.assign(payload.props, {
          commit: resolve,
          cancel: reject
        })
      })
    }

    commit(DIALOG_ACTIVE_UPDATE, payload)

    return promiseReturn
  },
  closeDialog({ commit }) {
    commit(DIALOG_ACTIVE_CLEAR)
  }
}

const mutations = {
  [DIALOG_ACTIVE_UPDATE](state, payload) {
    let { dialog, props = {} } = payload

    state.dialog = dialog
    state.props = props
  },
  [DIALOG_ACTIVE_CLEAR](state) {
    state.dialog = ''
    state.props = {}
  }
}

export default {
  state,
  getters,
  actions,
  mutations
}