<template>
  <div class="search__wrapper">
    <div :class="getClasses">
      <search-input
        v-on:clear-search="handleClearSearch"
        v-on:initiate-search="handleInitiateSearch"
        ref="searchInput">
      </search-input>

      <div class="search__popular-topics">
        <h3>Or Select a Common Topic</h3>

        <v-btn v-for="topic in popularTopics"
          :key="topic"
          round 
          class="btn--chip" 
          @click="handlePopularClick(topic)">{{ topic }}</v-btn>
      </div>

      <v-btn icon color="primary" @click="Mixin_active = !Mixin_active" class="search__close"><v-icon>expand_less</v-icon></v-btn>
    </div>

    <div class="search__overlay" v-if="Mixin_active"></div>
  </div>
</template>

<script>
  import { mapActions, mapState } from 'vuex'
  import debounce from 'lodash.debounce'
  import ExternalBind from '@/mixins/ExternalBind'
  import { MainBus } from '@/buses'

  let Search = {
    components: {},
    
    mixins: [ExternalBind],

    mounted() {
      MainBus.$on('edit-search', () => {
        this.Mixin_active = true
      })
    },

    watch: {
      // when Mixin_active becomes true, this animates open
      // see ExternalBind mixin
      // if true, set focus to the input field hidden way inside this component :)
      Mixin_active(val) {
        if (val) {
          setTimeout(() => {
            this
              .$refs.searchInput
              .$refs.input.focus()
          }, 650)
        }
      }
    },

    data() {
      return {
        popularTopics: [
          'Solar',
          'Water Harvesting',
          'Greenhouse',
          'Conservation',
          'Recycle',
          'LEED',
          'Green Fund',
          'Compost',
          'Emissions',
          'Biodiversity',
          'Landscape',
          'Landscape Basin',
          'Agriculture',
          'Rainwater',
          'Fossil Fuels',
          'Students for Sustainability'
        ]
      }
    },

    computed: {
      getClasses() {
        return {
          'search': true,
          'search--open': this.Mixin_active
        }
      }
    },

    methods: {
      ...mapActions(['initiateSearch', 'generalEvent']),
      handlePopularClick(val) {
        this.generalEvent({ category: 'search', action: 'common-topic', label: val })
        this.initiateSearch(val)
        this.Mixin_active = false
      },
      handleInitiateSearch(val) {
        this.Mixin_active = false
      },
      handleClearSearch() {
        this.Mixin_active = false
      }
    }
  }

  export default Search
</script>

<style lang="stylus">
  // @import "@/stylus/variables";

  .search {
    z-index: 7;
    position: fixed;
    display: flex;
    flex-direction: column;
    align-items: center;
    top: 0;
    right: 0;
    width: 100vw;
    background-color: $common-background;
    box-shadow: 0px 2px 4px -1px rgba(0,0,0,0.2),
                0px 4px 5px 0px rgba(0,0,0,0.14),
                0px 1px 10px 0px rgba(0,0,0,0.12);
    padding: 50px 15px;
    transform: translateY(-110%);
    transition: all .3s ease;

    .search-input {
      margin: 0 auto;
      max-width: 600px;
    }
  }

  .search--open {
    transform: translateY(0%);
  }

  .search__close {
    position: absolute !important;
    bottom: -20px;

    .icon {
      color: #fff !important;
    }
  }

  .search__popular-topics {
    padding: 30px;
    width: 60vw;
    margin: 0 auto;
    text-align: center;

    .btn--chip {
      text-transform: none;
    }

    h3 {
      font-size: 16px;
      text-transform: uppercase;
      margin-bottom: 10px;
    }

    @media(max-width: $sm-screen-breakpoint) {
      height: 200px;
      overflow: auto;
      -webkit-overflow-scrolling: touch;
    }
  }

  .search__overlay {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 6;
    pointer-events: none;
    background-color: rgba($color-dark-grey, 0.4);
  }

  @media (max-width: $md-screen-breakpoint) {
    .search--open { 
      .search__popular-topics {
        width: 95vw;
      }
    }
  }
</style>