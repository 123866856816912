let ListItemContent = {
  name: 'list-item-content',
  functional: true,
  render(createEl, { data, children }) {
    data.class = { 'list-item__content': true }

    return createEl('div', data, children)
  }
}

export default ListItemContent