<template>
  <list-item class="news-item" 
    @click="$router.push(getNewsRouteConfig(item.NewsID))"
    :key="item.NewsID">
    <list-item-content>
      <list-item-title>{{ item.Name }}</list-item-title>
      <list-item-subtitle>{{ getPubDate }}</list-item-subtitle>

      <div class="list-item__attributes">
        <pill>News</pill>
        <theme-circles :themes="[item.SustainabilityTheme]"/>

        <div class="news-item__external">
          <v-btn small icon @click.stop="handleExternalClick"><v-icon>open_in_new</v-icon></v-btn>
        </div>
      </div>  
    </list-item-content>

    <list-item-avatar>
      <div class="news-item__image" 
        :style="`background-image: url(${item.News_Thumb_Image})`"
        v-if="item.News_Thumb_Image"></div>
      <v-icon x-large v-else>forum</v-icon>
    </list-item-avatar>
  </list-item>
</template>

<script>
  import Leaflet from 'leaflet'
  import ThemeCircles from '@/components/ThemeCircles/ThemeCircles'
  import { mapGetters } from 'vuex'
  import { getHalfOfTopFortyPercent } from '@/utils/generalUtils'
  import moment from 'moment'

  let NewsItems = {
    components: {
      ThemeCircles
    },

    props: {
      item: {
        type: Object,
        required: true,
        default: () => {
          return {}
        }
      }
    },

    computed: {
      ...mapGetters([
        'getNewsById',
        'getMap',
        'getNewsRouteConfig'
      ]),
      getPubDate() {
        return moment(this.item.SortDate).format('MMM DD, YYYY')
      }
    },

    methods: {
      handlePinClick() {
        let layerBounds = Leaflet.geoJSON(this.getNewsById(this.item.NewsID)).getBounds()

        this.getMap('mainMap').fitBounds(layerBounds, { 
          paddingTopLeft: [0, -(getHalfOfTopFortyPercent())],
          maxZoom: 15
        })
      },
      handleExternalClick() {
        window.open(this.item.Field_url_link)
      }
    }
  }

  export default NewsItems
</script>

<style lang="stylus">
  // @import "@/stylus/variables"

  .news-item__attributes {
    display: flex;
    flex-direction: row;
    align-items: center;
  }

  .news-item__external {
    margin-left: 5px;

    .icon {
      font-size: 18px;
    }

    .btn--icon {
      margin: 0;
    }
  }

  .news-item__image {
    display: block;
    max-width: 100px !important;
    background-size: cover;
    background-position: center center;
  }
</style>
