// The Vue build version to load with the `import` command
// (runtime-only or standalone) has been set in webpack.base.conf with an alias.
import Vue from 'vue'
import Vuetify from 'vuetify'
import Vuelidate from 'vuelidate'
import App from './App'
import Raven from 'raven-js' // TODO: this has been replaced by @sentry/integrations https://docs.sentry.io/platforms/javascript/vue/
import RavenVue from 'raven-js/plugins/vue'
import store from './store/index'
import router from './router'
import { sync } from 'vuex-router-sync'
import MappKitLeaflet from '@vue-mapp-kit/leaflet'
import MappKitEsriLeaflet from '@vue-mapp-kit/esri-leaflet'
import VueClipboard from 'vue-clipboard2'
import infiniteScroll from 'vue-infinite-scroll';
import './plugins/gtm'

import Components from './components/_index'
import './registerServiceWorker'


if (process.env.NODE_ENV === 'production') {
  Raven
    .config(process.env.VUE_APP_RAVEN_URL, {
      ignoreUrls: [ /localhost:8080/i ]
    })
    .addPlugin(RavenVue, Vue)
    .install()
}

// Vue.config.performance = process.env.NODE_ENV !== 'production'

sync(store, router)

Vue.use(Vuetify, {
  theme: {
    primary: '#48595E',
    secondary: '#929B9E',
    accent: '#F19E1E'
  }
})

Vue.use(Vuelidate)
Vue.use(MappKitLeaflet, { store })
Vue.use(MappKitEsriLeaflet)
Vue.use(VueClipboard)
Vue.use(infiniteScroll)
Vue.config.productionTip = false

Object.keys(Components).forEach(key => {
  Vue.component(`${key}`, Components[key])
})

/* eslint-disable no-new */
new Vue({
  router,
  store,
  render: h => h(App),
}).$mount('#app')


// import Vue from 'vue'
// import App from './App.vue'

// Vue.config.productionTip = false

// new Vue({
//   render: h => h(App),
// }).$mount('#app')
