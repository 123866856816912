import Vue from 'vue'
import Vuex from 'vuex'
import gtmPlugin from './plugins/gtm'

import activities from './modules/activities'
import dialogs from './modules/dialogs'
import events from './modules/events'
import gtm from './modules/gtm'
import people from './modules/people'
import maps from './modules/maps'
import measure from './modules/measure'
import news from './modules/news'
import notifications from './modules/notifications'
import ui from './modules/ui'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {},
  actions: {},
  modules: {
    activities,
    dialogs,
    events,
    gtm,
    people,
    maps,
    measure,
    news,
    notifications,
    ui
  },
  plugins: [gtmPlugin]
})
