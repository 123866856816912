import Vue from 'vue'
import {
  FEATURES_UPDATE,
  PEOPLE_UPDATE,
  PEOPLE_UPDATE_RELATED_ACTIVITIES,
  MAP_SEARCH_UPDATE,
  UI_ADD_LOADING_STATE,
  UI_REMOVE_LOADING_STATE
} from '../mutation-types'
import store from '@/store'
import peopleApi from '@/api/people'
import relatedApi from '@/api/related'
import activitiesApi from '@/api/activities'
import { 
  PEOPLE_FETCH_DETAIL_FIELDS,
  PEOPLE_FIELDS_TO_SEARCH,
  SUSTAINABILITY_PEOPLE_LAYER
} from '@/utils/constants'
import { HandleError } from '@/utils/errorHandling'
import map from 'lodash.map'
import reduce from 'lodash.reduce'
import range from 'lodash.range'
import merge from 'lodash.mergewith'
import uniq from 'lodash.uniq'
import values from 'lodash.values'
import { createFriendlyURL } from '@/utils/generalUtils'


const state = {
  entities: {},
  result: []
}

const getters = {
  getPersonById: (state) => (id) => {
    return state.entities[id]
  },
  getPersonPropertiesById: (state, getters) => (id) => {
    return (getters.getPersonById(id))
      ? getters.getPersonById(id).properties
      : {}
  },
  getPeopleAsGeoJSON: (state) => {
    return state.result.map((id) => {
      return state.entities[id]
    })
  },
  getPersonThemes: (state, getters) => (id) => {
    const properties = getters.getPersonPropertiesById(id)

    return reduce(range(0, 5), (outcome, num) => {
      let theme = properties[`SustainabilityTheme${(num) ? num : ''}`]

      return (theme && theme !== 'Null')
        ? outcome.concat(theme)
        : outcome 
    }, [])
  },
  isPersonVisible: (state, getters) => (id) => {
    let { SustainabilityTheme = '' } = getters.getPersonPropertiesById(id)

    return getters.getVisibleThemesByName.includes(SustainabilityTheme) 
      && getters.isFilterVisibile('People')
  },
  getPersonRouteConfig: (state, getters) => (id) => {
    if (!getters.getPersonById(id)) throw new Error('Person does not exist, cannot route.')

    let title = getters.getPersonPropertiesById(id).Full_Name
    let urlFriendlyTitle = createFriendlyURL(title)

    return {
      name: 'peopleDetails',
      params: { id, title: urlFriendlyTitle }
    }
  },
  hasPersonBeenFetched: (state, getters) => (id) => {
    return !!getters.getPersonById(id) && getters.getPersonById(id).fetched
  }
}

const actions = {
  updatePeople({ commit, dispatch }, payload) {
    // payload should be { result, entities }
    commit(PEOPLE_UPDATE, payload)
  },
  async fetchPerson({ commit, dispatch }, id) {
    try {
      if (!id || id === 'Null') {
        console.error('[action] fetchPerson: id was "Null"')
        return
      }

      if (store.getters.hasPersonBeenFetched(id)) {
        return store.getters.getPersonById(id)
      }

      commit(UI_ADD_LOADING_STATE, 'loadingPerson')

      let person = await peopleApi.get(id, PEOPLE_FETCH_DETAIL_FIELDS)

      // if an EGISID return a null set, console it out as an error
      if (!person.result.length) console.error(`[action] fetchPerson: ${id} was not found in map service`)

      // "fetched" property added to inform application that full payload has been fetch
      if (person.entities[id]) person.entities[id].fetched = true

      commit(PEOPLE_UPDATE, person)
      commit(UI_REMOVE_LOADING_STATE, 'loadingPerson')

      return person.entities[id]
    } catch(e) {
      commit(UI_REMOVE_LOADING_STATE, 'loadingPerson')
      HandleError(new Error(`[action] fetchPerson: ${e}`))
    }
  },
  async getActivitiesByPeople({ commit, dispatch }, id) {
    try {
      let { relatedActivities = [] } = store.getters.getPersonById(id)

      if (relatedActivities.length) return relatedActivities

      commit(UI_ADD_LOADING_STATE, 'loadingRelatedActivities')

      let relatedResult = await relatedApi.getActivitiesByPeople(id)

      let egisIdsSql = map(relatedResult.result, (activityId) => {
        let { 
          properties: { 
            SA_EGISID = null 
          } = {} 
        } = relatedResult.entities[activityId]

        return `EGISID=${SA_EGISID}`
      })

      let activities = await activitiesApi.getWhere(egisIdsSql.join(' OR ') || "1=0")

      commit(FEATURES_UPDATE, activities)
      commit(PEOPLE_UPDATE_RELATED_ACTIVITIES, { id, activities: activities.result })
      commit(UI_REMOVE_LOADING_STATE, 'loadingRelatedActivities')

      return activities.result
    } catch(e) {
      commit(UI_REMOVE_LOADING_STATE, 'loadingRelatedActivities')
      HandleError(new Error(`[action] getActivitiesByPeople: ${e}`))
    }
  },
  async searchPeople({ commit, getters }, text) {
    try {
      commit(UI_ADD_LOADING_STATE, 'searchingPeople')

      const people = await peopleApi.find({
        service: getters.getNamedLayer('Master'),
        text,
        fields: PEOPLE_FIELDS_TO_SEARCH,
        layers: SUSTAINABILITY_PEOPLE_LAYER
      })

      let { entities = {} } = people

      commit(PEOPLE_UPDATE, people)

      // commit to search happens in store/modules/maps
      // commit(MAP_SEARCH_UPDATE, values(entities))
      commit(UI_REMOVE_LOADING_STATE, 'searchingPeople')

      return people
    } catch(e) {
      commit(UI_REMOVE_LOADING_STATE, 'searchingPeople')
      HandleError(new Error(`[action] searchingPeople: ${e}`))
    }
  }
}

const mutations = {
  [PEOPLE_UPDATE](state, { result, entities }) {
    // TODO: need to do a more intelligent merge to not overwrite geometry
    
    // this customizer function is used with "mergeWith"
    // it checks if current entity has geometry, if yes, doesn't merge
    // const checkGeometry = (entitiesValue, payloadValue) => {
    //   if (entitiesValue) {
    //     return Object.assign(entitiesValue, payloadValue, {
    //       geometry: entitiesValue.geometry
    //     })
    //   }
    // }

    state.result = uniq(state.result.concat(result)).slice()
    state.entities = merge({}, state.entities, entities)
  },
  [PEOPLE_UPDATE_RELATED_ACTIVITIES](state, payload) {
    let { id, activities } = payload

    if (!Array.isArray(activities)) HandleError(new Error(`[mutations] PEOPLE_UPDATE_RELATED_ACTIVITIES: activities not an Array`))

    let newActivities = uniq(state.entities[id].relatedActivities.concat(
      map(activities, id => Number(id))
    ))

    Vue.set(state.entities[id], 'relatedActivities', newActivities)
  }
}

export default {
  state,
  getters,
  actions,
  mutations
}
