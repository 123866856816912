<template>
  <div class="list-item-loading">
    <div class="list-item-loading__top">
    </div>

    <div class="list-item-loading__bottom">
    </div>
  </div>
</template>

<script>
  let ListItemLoading = {
    components: {},
    data() {
      return {}
    }
  }

  export default ListItemLoading
</script>

<style lang="styl">
  // @import "@/stylus/variables";

  .list-item-loading {
    width: 100%;
  }

  .list-item-loading__top {
    width: 100%;
    background-color: rgba($color-grey, 0.3);
    height: 60px;
    margin-bottom: 10px;
  }

  .list-item-loading__bottom {
    width: 35%;
    background-color: rgba($color-grey, 0.15);
    height: 25px;
  }
</style>