import {
  UI_ADD_LOADING_STATE,
  UI_REMOVE_LOADING_STATE,
  UI_UPDATE_SEARCH_TEXT,
  UI_UPDATE_MAIN_SIDEBAR_OPEN,
  UI_UPDATE_MASTER_LIST_SIDEBAR_OPEN,
  UI_UPDATE_DETAILS_SIDEBAR_OPEN,
  UI_SHALLOW_HISTORY_UDATE,
  MAP_FILTER_VISIBILITY_UPDATE,
  UI_UPDATE_EMBED,
  MAP_SEARCH_CLEAR
} from '../mutation-types'
import store from '@/store'
import router from '@/router'
import union from 'lodash.union'
import indexOf from 'lodash.indexof'
import { HandleError } from '@/utils/errorHandling'

const state = {
  dynamicStates: [],
  searchText: '',
  mainSideBarOpen: true,
  mainSideBarWidth: 330,
  masterListSidebarOpen: false,
  masterListSidebarWidth: 350,
  searchSidebarWidth: 350,
  detailsSideBarOpen: false,
  detailsSideBarWidth: 350,
  embed: false,
  dynamicPanelHistory: []
}

// getters
const getters = {
  isLoading: (state) => (value) => {
    return state.dynamicStates.includes(value)
  },
  isCompleted: (state, getters) => (value) => {
    return getters.isLoading(value)
  },
  isInProgress: (state) => (value) => {
    return state.dynamicStates.includes(value)
  },
  isLastPageList(state, getters) {
    return !!(state.dynamicPanelHistory[1] && state.dynamicPanelHistory[1].includes('-sidebar'))
  },
  isLastPageDetails(state, getters) {
    return !!(state.dynamicPanelHistory[1] && state.dynamicPanelHistory[1].includes('-details'))
  },
  isHistoryDetailsPanelOrSearchList(state, getters) {
    return getters.isLastPageDetails || getters.isInProgress('searchMode')
  }
}

const actions = {
  // this action is purely for setting a route
  // Search.vue triggers an action to execute actual search
  async initiateSearch({ commit, dispatch }, text) {
    if (!text) console.info('No search text was provide')

    if (text) {
      commit(UI_ADD_LOADING_STATE, 'searchMode')
      router.push({ name: 'search', params: { searchText: text } })
    }
  },
  clearSearch({ commit }) {
    router.push({ name: 'home' })

    commit(UI_UPDATE_SEARCH_TEXT, '')
    commit(MAP_SEARCH_CLEAR)
    commit(UI_REMOVE_LOADING_STATE, 'searchMode')
  },
  updateDynamicPanelHistory({ commit }, newHistory) {
    let historyCopy = state.dynamicPanelHistory.slice()

    // if new panel coming in is not the same as what is already on the top of the stack
    // put it at the top of the stack
    if (historyCopy[0] !== newHistory) historyCopy.unshift(newHistory)

    if (historyCopy.length > 10) historyCopy.pop()

    commit(UI_SHALLOW_HISTORY_UDATE, historyCopy)
  },
  shiftDynamicPanelHistory({ commit }, historyToPop) {
    let historyCopy = state.dynamicPanelHistory.slice()
    if (historyCopy.length && historyCopy[0] == historyToPop) historyCopy.shift()

    commit(UI_SHALLOW_HISTORY_UDATE, historyCopy)
  }
}

const mutations = {
  [UI_ADD_LOADING_STATE](state, value) {
    // lodash.union ensures that state.dynamicStates is a unique list
    state.dynamicStates = union(state.dynamicStates, [value])
  },
  [UI_REMOVE_LOADING_STATE](state, value) {
    let workingCopy = state.dynamicStates.slice()
    let index = indexOf(workingCopy, value)

    if (index !== -1) {
      workingCopy.splice(index, 1)
      state.dynamicStates = workingCopy
    }
  },
  [UI_UPDATE_SEARCH_TEXT](state, value) {
    state.searchText = value
  },
  [UI_UPDATE_MAIN_SIDEBAR_OPEN](state, value) {
    state.mainSideBarOpen = value
  },
  [UI_UPDATE_MASTER_LIST_SIDEBAR_OPEN](state, value) {
    state.masterListSidebarOpen = value
  },
  [UI_UPDATE_EMBED](state, value) {
    state.embed = value
  },
  [UI_UPDATE_DETAILS_SIDEBAR_OPEN](state, value) {
    state.detailsSideBarOpen = value
  },
  [UI_SHALLOW_HISTORY_UDATE](state, newShallowHistory = []) {
    state.dynamicPanelHistory = newShallowHistory
  }
}

export default {
  state,
  getters,
  actions,
  mutations
}
