<template>
  <filter-list :class="getClasses"
    :title="!searchMode ? 'Master List' : `${searchText} (${getSearchLayers.length} results)`"
    :items="items" 
    search-label="Refine list by keyword" 
    :loading="showLoader"
    :sort-by="getSortOptionValue"
    :group-by="groupBy"
    :quick-filter-predicates="getPredicates"
    filter-sort
    ref="filterList">

    <span slot="title">
      <v-btn icon @click.stop="handleClose">
        <v-icon medium>chevron_right</v-icon>
      </v-btn>
      {{
        !searchMode ? 'Master List' : `${searchText} (${getSearchLayers.length} results)`
      }}
      <v-btn class="master-list__close-search hidden-md-and-up" 
        icon 
        @click.stop="handleSearchEdit"
        v-if="searchMode">
        <v-icon>edit</v-icon>
      </v-btn>
    </span>

    <v-container slot="menu" class="pa-0">
      <v-checkbox class="master-list__active-check"
        label="List only active items" 
        v-model="activeStatusFilter"></v-checkbox>
      <v-layout row>
        <v-flex xs4>
          <v-subheader>Sort by</v-subheader>
          <v-radio-group v-model="sortBy">
            <v-radio
              color="primary"
              v-for="(option, index) in getSortRadios" 
              :key="index"
              :label="option.label"
              :value="option.label"
            ></v-radio>
          </v-radio-group>
        </v-flex>

        <v-flex xs4>
          <v-subheader>Group by</v-subheader>
          <v-radio-group v-model="groupBy">
            <v-radio
              color="primary"
              v-for="(option, index) in groupOptions" 
              :key="index"
              :label="option.label"
              :value="option.value"
            ></v-radio>
          </v-radio-group>
        </v-flex>

        <v-flex xs4></v-flex>
      </v-layout>
    </v-container>

    <template slot-scope="props" slot="group-headings">
      <li :class="getThemeHeadingClasses(props.heading)" v-if="groupBy === 'SustainabilityTheme'">
        <icon-theme :key="props.heading" :theme="props.heading"/>
        <span>{{ (props.heading == 'undefined') ? 'No Theme Heading' : props.heading }}</span>
      </li>

      <li class="master-list__layer-heading" v-if="groupBy === 'SustainabilityType'">
        <span>{{ getLayerHeading(props.heading) }}</span>
      </li>
    </template>

    <div class="master-list__click-wrapper" @click="handleCapture" slot-scope="props" slot="list">
      <activity-item :activity="props.item" v-if="props.item.isActivity"/>
      <person-item :info="props.item" v-if="props.item.isPerson"/>
      <news-item :item="props.item" v-if="props.item.isNews"/>
      <event-item :item="props.item" v-if="props.item.isEvent"/>
    </div>

    <list-item-content slot="empty-item">
      <list-item-title>Sorry!</list-item-title>
      <list-item-description v-if="areOnlyTheseFiltersVisible('Features')">
        We have so many <em>Green Features</em> that we can't display them all in a list!
        <br />
        <br />
        Explore the map to learn more.
      </list-item-description>
      <list-item-description v-else>
        Nothing matched your quick filter or nothing is rendered in the boundaries of the map.
      </list-item-description>
    </list-item-content>
  </filter-list>
</template>

<script>
  import ActivityItem from '@/components/ActivityItem/ActivityItem'
  import PersonItem from '@/components/PersonItem/PersonItem'
  import NewsItem from '@/components/NewsItem/NewsItem'
  import EventItem from '@/components/EventItem/EventItem'
  import DisplayManager from '@/mixins/DisplayManager'
  import { STATUS_ACTIVE_VALUES } from '@/utils/constants'
  import { UI_UPDATE_MASTER_LIST_SIDEBAR_OPEN } from '@/store/mutation-types'
  import { mapState, mapGetters, mapMutations, mapActions } from 'vuex'
  import reduce from 'lodash.reduce'
  import map from 'lodash.map'
  import debounce from 'lodash.debounce'
  import difference from 'lodash.difference'
  import Leaflet, { LatLngBounds } from 'leaflet'
  import { MainBus } from '@/buses'


  const DEFAULT_GROUP = false
  const LIST_REFRESH_DEBOUNCE = 500

  let MasterList = {
    components: {
      ActivityItem,
      PersonItem,
      NewsItem,
      EventItem
    },

    mixins: [DisplayManager],

    // any getter that represents add/removing items on the map should be added as watcher
    watch: {
      getVisibleThemes: 'handleSetItems',
      getVisibleFilters: 'handleSetItems',
      getVisibleFilters: {
        handler(filters) {
          this.handleSetItems()

          // check to see if News or Events are the only filters selected
          // if yes, change the default sort value
          if (this.areOnlyTheseFiltersVisible('News')) this.sortBy = 'Newest'
          if (this.areOnlyTheseFiltersVisible('Events')) this.sortBy = 'Upcoming'

          // check to see if the sort value is no longer a "radio" options
          // if no longer an options, default it back to "A-Z"
          const isSortSet = this.getSortRadios.find(sort => {
            return this.sortBy === sort.label
          })

          if (!isSortSet) this.sortBy = 'A-Z'
        },
        immediate: true
      },
      getSearchLayers: 'handleSetItems'
    },

    mounted() {
      this.setItems()

      this.$mappKitBus.$on('map-mainMap-zoomend', this.handleSetItems)
      this.$mappKitBus.$on('map-mainMap-moveend', this.handleSetItems)
    },

    props: {
      loading: Boolean
    },

    data() {
      return {
        activeStatusFilter: false,
        items: [],
        internalLoading: false,
        forceUpdate: '',
        setItemsDebounce: debounce(this.setItems, LIST_REFRESH_DEBOUNCE),
        // example of quick filter, not used in master list

        sortOptions: [
          {
            label: 'A-Z',
            value: { field: 'Name', order: 'asc' }
          },
          {
            label: 'Z-A',
            value: { field: 'Name', order: 'desc' }
          },
          {
            label: 'Newest',
            value: { field: 'SortDate', order: 'desc' },
            predicate: () => {
              return this.areOnlyTheseFiltersVisible('News')
            }
          },
          {
            label: 'Oldest',
            value: { field: 'SortDate', order: 'asc' },
            predicate: () => {
              return this.areOnlyTheseFiltersVisible('News')
            }
          },
          {
            label: 'Upcoming',
            value: { field: 'SortDate', order: 'asc' },
            predicate: () => {
              return this.areOnlyTheseFiltersVisible('Events')
            }
          }
        ],
        groupOptions: [
          {
            label: 'Themes',
            value: 'SustainabilityTheme'
          },
          {
            label: 'Layers',
            value: 'SustainabilityType'
          },
          {
            label: 'None',
            value: DEFAULT_GROUP
          }
        ],
        sortBy: 'A-Z',
        groupBy: DEFAULT_GROUP
      }
    },

    computed: {
      ...mapState({
        people: state => state.people.result,
        activites: state => state.activities.result,
        eventIds: state => state.events.result,
        newsIds: state => state.news.result,
        searchLayers: state => state.maps.layers.search.layers,
        searchText: state => state.ui.searchText,
        mainSideBarWidth: state => state.ui.mainSideBarWidth,
        masterListSidebarWidth: state => state.ui.masterListSidebarWidth
      }),
      ...mapGetters([
        'getPersonById',
        'getActivityById',
        'isFilterVisibile',
        'isActivityVisible',
        'isNewsItemVisible',
        'isEventItemVisible',
        'getMap',
        'getNewsById',
        'getEventById',
        'getNamedLayer',
        'getVisibleFilterFeatures',
        'isPersonVisible',
        'getActivityThemes',
        'getVisibleThemes',
        'getThemeByName',
        'getSearchLayers',
        'getVisibleFilters'
      ]),
      getPredicates() {
        let predicates = []

        if (this.activeStatusFilter) predicates.push(this.checkActiveStatus)
        // push more predicates here

        return predicates
      },
      getSortOptionValue() {
        // return the "value" of a sort option based on its label
        // the "value" is the object that configures which field and order to sort by
        const sort = this.sortOptions.find(sort => {
          return sort.label === this.sortBy
        })

        return sort.value
      },
      getClasses() {
        return {
          'master-list': true,
          'master-list--search-mode': this.searchMode
        }
      },
      // loops through sortOptions and return which radios to display
      // the predicate should return true/false
      getSortRadios() {
        return this.sortOptions.filter(obj => {
          if (obj.predicate) return obj.predicate()

          return true
        })
      },
      showLoader () {
        return this.searchLoading || this.internalLoading
      }
    },

    methods: {
      ...mapMutations({
        toggleMasterListSidebar: UI_UPDATE_MASTER_LIST_SIDEBAR_OPEN
      }),
      ...mapActions(['generalEvent']),
      checkActiveStatus({ Status = '' }) {
        const innerStatus = (Status !== null) ? Status : ''
        const activeStatusWhiteList = STATUS_ACTIVE_VALUES

        return activeStatusWhiteList.includes(innerStatus.toLowerCase())
      },
      handleClose() {
        this.toggleMasterListSidebar(false)
      },
      handleSearchEdit() {
        MainBus.$emit('edit-search')
      },
      handleSetItems() {
        this.setItemsDebounce.cancel()

        this.setItemsDebounce()
      },
      handleCapture() {
        // this is attached to the outer div of the master list items
        // click propagation will call the item click and div click
        this.generalEvent({ category: 'feature', action: 'click', label: 'master-list' })
      },
      areOnlyTheseFiltersVisible(...filters) {
        // helper function to determine which filters are visible
        // ...filters turns 'News', 'Events', 'People' into an array ['News', 'Events', 'People']
        return (this.getVisibleFilters.length)
          ? !difference(this.getVisibleFilters, filters).length
          : false
      },
      setItems() {
        this.internalLoading = true
        // setting this data property to a new date string forces "getPeople" and "getActivities" to re-computed
        this.forceUpdate = new Date().toString()

        // fake internalLoading to get the user to "pay attention"
        setTimeout(() => {
          this.items = (this.searchMode) 
            ? this.getSearchItems()
            : this.getActivities().concat(
              this.getPeople(),
              this.getEventItems(),
              this.getNewsItems(),
              this.getGreenFeatures())
          this.internalLoading = false
        }, 500)
      },
      getPeople() {
        this.forceUpdate = this.forceUpdate

        return reduce(this.people, (outcome, PeopleID) => {
          let { 
            properties = {},
            geometry = {}
          } = this.getPersonById(PeopleID) || {}

          if (geometry && this.isPersonVisible(PeopleID) && this.inMapBounds(geometry)) outcome.push(properties)

          return outcome
        }, [])
      },
      getActivities() {
        this.forceUpdate = this.forceUpdate

        return reduce(this.activites, (outcome, EGISID) => {
          let activity = this.getActivityById(EGISID)

          let { 
            properties = {},
            properties: {
              SustainabilityType
            } = {},
            geometry = {}
          } = activity

          if (geometry && this.isActivityVisible(EGISID) && this.inMapBounds(geometry)) outcome.push(properties)

          return outcome
        }, [])
      },
      getGreenFeatures() {
        this.forceUpdate = this.forceUpdate

        // if (this.isFilterVisibile('Features')) {
        //   let service = this.getNamedLayer('green-features')

        //   let bounds = this.getMap('mainMap').getBounds()
        //   mapsApi.within({ service, bounds, layers: this.getVisibleFilterFeatures }).then((response) => {
        //     debugger
        //   })
        // }

        return []
      },
      getNewsItems() {
        this.forceUpdate = this.forceUpdate

        return reduce(this.newsIds, (outcome, NewsID) => {
          let { 
            properties = {},
            geometry = {}
          } = this.getNewsById(NewsID) || {}

          if (geometry && this.isNewsItemVisible(NewsID) && this.inMapBounds(geometry)) outcome.push(properties)

          return outcome
        }, [])
      },
      getEventItems() {
        this.forceUpdate = this.forceUpdate

        return reduce(this.eventIds, (outcome, EventID) => {
          let { 
            properties = {},
            geometry = {}
          } = this.getEventById(EventID) || {}

          if (geometry && this.isEventItemVisible(EventID) && this.inMapBounds(geometry)) outcome.push(properties)

          return outcome
        }, [])
      },
      getSearchItems() {
        this.forceUpdate = this.forceUpdate

        return reduce(this.searchLayers, (outcome, layer) => {
          let {
            properties,
            properties: {} = {},
            geometry = {}
          } = layer

          if (
            this.searchMode
            && geometry 
            && geometry.coordinates 
            && this.inMapBounds(geometry)
          ) outcome.push(properties)

          return outcome
        }, [])
      },
      inMapBounds(geometry = {}) {
        let { coordinates = [], type } = geometry

        if (!coordinates.length) return false

        let latlng;
        let innerCoords = coordinates.slice()

        switch(type) {
          case 'Point':
            latlng = L.GeoJSON.coordsToLatLng(innerCoords)

            break;
          case 'MultiPoint':
            latlng = L.GeoJSON.coordsToLatLngs(innerCoords)

            break;
          case 'Polygon':
            latlng = L.GeoJSON.coordsToLatLngs(innerCoords, 1)

            break;
          case 'MultiPolygon':
            latlng = L.GeoJSON.coordsToLatLngs(innerCoords, 2)

            break;
          case 'Polyline':
            latlng = L.GeoJSON.coordsToLatLngs(innerCoords, 1)

            break;
          case 'LineString':
            latlng = L.GeoJSON.coordsToLatLngs(innerCoords)

            break;
          case 'MultiLineString':
            latlng = L.GeoJSON.coordsToLatLngs(innerCoords, 1)
            
            break;
        }

        const temp = LatLngBounds
        const mainMap = this.getMap('mainMap')
        const smallScreen = this.$vuetify.breakpoint.smAndDown
        const innerBounds = (Array.isArray(latlng)) ? new LatLngBounds(latlng) : new LatLngBounds([latlng])

        const getViewportBounds = () => {
          // get top left corner and bottom right corner of visible extent on the map
          const top = 0

          // check if theme sidebar in in "mini" state
          const left = (document.getElementsByClassName('navigation-drawer--mini-variant').length || smallScreen) ? 70 : this.mainSideBarWidth 
          
          const bottom = (smallScreen) ? window.innerHeight * .4 : window.innerHeight
          const right = (smallScreen) ? window.innerWidth : (window.innerWidth - this.masterListSidebarWidth)

          let topleft = Leaflet.point(left, top)
          let bottomRight = Leaflet.point(right, bottom)

          return Leaflet.bounds(topleft, bottomRight)
        }

        const getViewportLatLngBounds = () => {
          var bounds = getViewportBounds()
          return new LatLngBounds(mainMap.containerPointToLatLng(bounds.min), mainMap.containerPointToLatLng(bounds.max))
        }

        // check for both contains and overlaps to account for multi-dimensional geo types
        const outerBounds = getViewportLatLngBounds()
        return outerBounds.contains(innerBounds) || outerBounds.intersects(innerBounds)
        // return outerBounds.contains(innerBounds)
      },
      getThemeHeadingClasses(theme) {
        let { color = '' } = this.getThemeByName(theme)

        return {
          'master-list__theme-heading': true,
          [`master-list__theme-heading--${color}`]: color
        }
      },
      getLayerHeading(layer) {
        const layers = {
          'Green Feature': 'Green Features',
          'undefined': 'No Layer Heading'
        }

        return layers[layer] || layer
      }
    }
  }

  export default MasterList
</script>

<style lang="stylus" scope>
  // @import "@/stylus/variables";

  .master-list {
    &.filter-list {
      .filter-list__collapse-wrapper {
        border-bottom: none;
      }

      .filter-list__collapse-trigger {
        background-color: $color-dark-grey;
        
        .filter-list__title {
          margin-left: -10px;
        }

        .filter-list__title,
        .filter-list__icon-label {
          color: #fff;
        }
        
        .btn--icon .icon {
          color: #fff;
        }

        .master-list__close-search {
          margin-top: -2px;
          margin-left: 2px;

          .icon {
            color: $color-dark-grey;
          }
        }
      }
    }
  }
  
  .master-list--search-mode {
    &.filter-list {      
      .filter-list__title {
        text-transform: none;
        font-size: 14px;
      }
    }
  }

  .master-list__close-search {
    background-color: #fff;
    width: 20px;
    height: 20px;

    .icon {
      color: $color-dark-grey;
      font-size: 16px;
    }
  }

  .master-list__layer-heading,
  .master-list__theme-heading {
    padding: 15px;
    display: flex;
    align-items: center;
    font-weight: bold;
    text-transform: uppercase;
    background-color: $color-grey;
  
    span { color: #fff; }

    .icon-theme {
      font-size: 24px !important;
      margin-right: 10px;
    }
  }

  .master-list__active-check {
    margin-top: -10px;
    margin-left: -13px;
    margin-bottom: 15px;

    label {
      font-size: 14px;
      margin-left: -5px;
    }
  }
  
  .master-list__theme-heading--green {
    background-color: $color-green;
  }

  .master-list__theme-heading--teal {
    background-color: $color-teal;
  }

  .master-list__theme-heading--purple {
    background-color: $color-purple;
  }

  .master-list__theme-heading--yellow {
    background-color: $color-yellow;
  }

  .master-list__theme-heading--orange {
    background-color: $color-orange;
  }

  .master-list__theme-heading--brown {
    background-color: $color-brown;
  }

  .master-list__theme-heading--dark-grey {
    background-color: $color-dark-grey;
  }

  .master-list__theme-heading--blue {
    background-color: $color-blue;
  }
</style>
