<template>
  <icon-moon :name="getThemeObject.slug"
    :class="`icon-theme icon-theme--${getThemeObject.color}`"
    :size="size"
    v-if="getThemeObject && theme && theme !== 'Null'"/>
</template>

<script>
  import { mapGetters } from 'vuex'

  let IconTheme = {
    props: ['theme', 'size'],

    data() {
      return {}
    },

    computed: {
      ...mapGetters(['getThemeByName']),
      getThemeObject() {
        return this.getThemeByName(this.theme)
      }
    }
  }

  export default IconTheme
</script>

<style lang="styl">
  // @import "@/stylus/variables";

  .icon-theme--green {
    background-color: $color-green;
  }

  .icon-theme--teal {
    background-color: $color-teal;
  }

  .icon-theme--purple {
    background-color: $color-purple;
  }

  .icon-theme--yellow {
    background-color: $color-yellow;
  }

  .icon-theme--orange {
    background-color: $color-orange;
  }

  .icon-theme--brown {
    background-color: $color-brown;
  }

  .icon-theme--dark-grey {
    background-color: $color-dark-grey;
  }

  .icon-theme--blue {
    background-color: $color-blue;
  }

  .icon-theme {
    padding: 7px;
    border-radius: 50%;
    
    &+ .icon-theme {
      margin-left: 5px;
    }
  }
</style>