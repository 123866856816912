import {
  MEASUREMENT_MODE_UPDATE,
  MEASUREMENT_UNIT_UPDATE,
  MEASUREMENT_DATA_UPDATE
} from '../mutation-types'

const state = {
  data: {},
  mode: false,
  unit: ''
}

const getters = {
  isMeasurementArea(state) {
    return state.mode == 'measureArea'
  }
}

const actions = {
  async setMeasurementMode({ dispatch, commit, getters }, mode) {
    // this simulates a toggle effect
    // if mode (payload) is the same as current state.mode, then clear the measurement
    if (mode === state.mode) {
      dispatch('clearMeasurement')
    } else {
      dispatch('generalEvent', { category: 'map-controls', action: 'click', label: mode })
      commit(MEASUREMENT_MODE_UPDATE, mode)
    }

    // sets default "units" when the measurement begins
    ;(getters.isMeasurementArea)
      ? await dispatch('setMeasurementUnit', 'acres')
      : await dispatch('setMeasurementUnit', 'feet')
  },
  setMeasurementUnit({ commit }, unit) {
    commit(MEASUREMENT_UNIT_UPDATE, unit)
  },
  updateMeasurementData({ commit }, data) {
    commit(MEASUREMENT_DATA_UPDATE, data)
  },
  clearMeasurement({ commit }) {
    // resets state to clear measurement sidebar and reset map control icons
    commit(MEASUREMENT_MODE_UPDATE, false)
    commit(MEASUREMENT_DATA_UPDATE, {})
  }
}

const mutations = {
  [MEASUREMENT_MODE_UPDATE](state, mode) {
    state.mode = mode
  },
  [MEASUREMENT_UNIT_UPDATE](state, unit) {
    state.unit = unit
  },
  [MEASUREMENT_DATA_UPDATE](state, data) {
    state.data = data
  }
}

export default {
  state,
  getters,
  actions,
  mutations
}