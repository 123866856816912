import { mapActions, mapMutations } from 'vuex'
import { UI_SHALLOW_HISTORY_UDATE } from '@/store/mutation-types'

export default {
  watch: {
    value(newValue) {
      this.DynamicPanelContainerMixin_active = newValue
    },
    DynamicPanelContainerMixin_active(value) {
      if (value) this.updateDynamicPanelHistory(this.$options.name)

      this.$emit('input', this.DynamicPanelContainerMixin_active)
    }
  },

  props: {
    value: {
      type: Boolean,
      default: false
    }
  },

  data() {
    return {
      DynamicPanelContainerMixin_active: false
    }
  },

  methods: {
    ...mapActions([
      'updateDynamicPanelHistory',
      'shiftDynamicPanelHistory'
    ]),
    ...mapMutations({
      clearPaneHistory: UI_SHALLOW_HISTORY_UDATE
    }),
    DynamicPanelContainerMixin_handleClose() {
      // this close method does not come into play when the opening of another panel
      // closes a previous panel
      this.DynamicPanelContainerMixin_active = false
      // this.DynamicPanelContainerMixin_handleHistoryPop()
      this.clearPaneHistory()

      this.$router.push({ name: 'home' })

      // make sure the container has this in it's internal DynamicPanelContainerMixin_handleClose
      // setTimeout(() => {}, (1000 * 0.40))
    },
    DynamicPanelContainerMixin_handleHistoryPop() {
      // this removes the current panel from history
      // if the user clicks on the back button
      this.shiftDynamicPanelHistory(this.$options.name)
    }
  },

  beforeRouteLeave(to, from, next) {
    this.DynamicPanelContainerMixin_active = false

    setTimeout(() => {
      next()
    }, (1000 * 0.40))
  }
}