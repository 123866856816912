import Vue from 'vue'
import {
  USER_LOGIN,
  USER_LOGOUT
} from '../mutation-types'

const mutationLookup = {}

export default function gtmPlugin(store) {
  store.subscribe((m, s) => {
    try {
      let mutation = m.type.split('/')[0]
      let payload = mutationLookup[mutation] && mutationLookup[mutation](s)

      if (payload) {
        Vue.gtm.trackEvent({
          event: 'mutation',
          noninteraction: mutation,
          ...payload
        })
      }
    } catch(e) {
      throw new Error(`Error with gtm.trackEvent: ${e}`)
    }
  })
}
