import mapsApi, { negotiateResponse } from './maps'
import reduce from 'lodash.reduce'
import find from 'lodash.find'
import mapkeys from 'lodash.mapkeys'
import clonedeep from 'lodash.clonedeep'
import { query as esriQuery } from 'esri-leaflet'
import { 
  SUSTAINABILITY_MAP_SERVER_URL,
  SUSTAINABILITY_PEOPLE_LAYER
} from '@/utils/constants'

/**
 * Normalize a collection of GeoJSON features into result and entities and re-project lat/lng
 *
 * @param {array} collection GeoJSON Feature Collection 
 * @returns {Object} { results: [123, 456], entities: { 123: ..., 456: ... } }
 */
export function normalizePeopleCollection(collection = [], fullResult) {
  try {
    let result = []
    let _collection = clonedeep(collection)

    let entities = reduce(_collection, (outcome, feature) => {
      let properties = mapkeys(feature.properties, function(value, key) {
        let keyValueArray = key.split('.')
        return keyValueArray[keyValueArray.length - 1]
      })

      feature.properties = properties

      let { 
        properties: {
          PeopleID,
          OBJECTID,
          Full_Name,
          PeopleStatus,
          Photo
        } = {}
      } = feature

      let useId = String(PeopleID || OBJECTID)

      // possible additional properties
      // TODO: these fields now exist, but no data
      // feature.properties = { ...feature.properties, PeopleID: useId }
      feature.properties = {
        ...feature.properties,
        PeopleID: useId,
        SustainabilityType: 'People',
        Status: PeopleStatus,
        isPerson: true,
        Photo: (Photo) ? Photo.replace(' ', '%20') : null
      }

      feature.properties.Name = Full_Name
      feature.relatedActivities = []


      if (!PeopleID) console.error("[normalizePeopleCollection]", ': Feature in payload did not contain PeopleID')

      outcome[useId] = Object.assign({}, feature)
      result.push(useId)

      return outcome
    }, {})

    return { result, entities }
  } catch(e) {
    throw new Error(`normalizePeopleCollection: ${e}`)
  }
}

const peopleApi = {
  find({ service, text, fields = '*', layers }) {
    return new Promise((resolve, reject) => {
      mapsApi.find({ service, text, fields, layers })
        .then(({ features, results }) => resolve(normalizePeopleCollection(features, results)))
        .catch(reject)
    })
  },

  getWhere({ service, sql, layer }) {
    return new Promise((resolve, reject) => {
      mapsApi.getWhere({ service, sql, layer })
        .then(({ features, results }) => resolve(normalizePeopleCollection(features, results)))
        .catch(reject)
    })
  },

  identify({ geometry, service, map, layers }) {
    return new Promise((resolve, reject) => {
      mapsApi.identify({ geometry, service, map, layers })
        .then(({ features, results }) => resolve(normalizePeopleCollection(features, results)))
        .catch(reject)
    })
  },

  get(id, fields = []) {
    if (!id) throw new Error('[api] people get: no id parameter')
    let query = esriQuery({ url: `${SUSTAINABILITY_MAP_SERVER_URL}/${SUSTAINABILITY_PEOPLE_LAYER}` })

    if (fields.length) query.fields(fields)

    return new Promise((resolve, reject) => {
      query.where(`PeopleID=${id}`).run((error, featureCollection, response) => {
        negotiateResponse(error, featureCollection, response)
          .then(({ features, results }) => resolve(normalizePeopleCollection(features, results)))
          .catch(reject)
      })
    })
  }
}

export default peopleApi
