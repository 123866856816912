import {
  MAP_SEARCH_UPDATE,
  EVENTS_UPDATE,
  UI_ADD_LOADING_STATE,
  UI_REMOVE_LOADING_STATE
} from '../mutation-types'
import {
  EVENTS_FETCH_DETAILS_FIELDS,
  EVENTS_FIELDS_TO_SEARCH,
  SUSTAINABILITY_EVENTS_LAYER
} from '@/utils/constants'
import eventsApi from '@/api/events'
import { HandleError } from '@/utils/errorHandling'
import merge from 'lodash.merge'
import uniq from 'lodash.uniq'
import values from 'lodash.values'
import { createFriendlyURL } from '@/utils/generalUtils'

const state = {
  entities: {},
  result: []
}

const getters = {
  getEventById: (state, getters) => (id) => {
    return state.entities[id]
  },
  getEventPropertiesById: (state, getters) => (id) => {
    return (getters.getEventById(id))
      ? getters.getEventById(id).properties
      : {}
  },
  isEventItemVisible: (state, getters) => (id) => {
    let { SustainabilityTheme = '' } = getters.getEventPropertiesById(id)

    return getters.getVisibleThemesByName.includes(SustainabilityTheme) &&
      getters.isFilterVisibile('Events')
  },
  getEventRouteConfig: (state, getters) => (id) => {
    if (!getters.getEventById(id)) throw new Error('Event does not exist, cannot route.')

    let title = getters.getEventPropertiesById(id).Name
    let urlFriendlyTitle = createFriendlyURL(title)

    return {
      name: 'eventDetails',
      params: { id, title: urlFriendlyTitle }
    }
  },
  hasEventBeenFetched: (state, getters) => (id) => {
    return !!getters.getEventById(id) && getters.getEventById(id).fetched
  }
}

const actions = {
  async fetchEvent({ commit, dispatch, getters }, id) {
    try {
      if (!id || id === 'Null') {
        console.error('[action] fetchEvent: id was "Null"')
        return
      }

      if (getters.hasEventBeenFetched(id)) {
        return getters.getEventById(id)
      }

      commit(UI_ADD_LOADING_STATE, 'loadingEvent')

      let event = await eventsApi.get(id, EVENTS_FETCH_DETAILS_FIELDS)

      // if return a null set, console it out as an error
      if (!event.result.length) console.error(`[action] fetchEvent: ${id} was not found in map service`)

      // "fetched" property added to inform application that full payload has been fetch
      if (event.entities[id]) event.entities[id].fetched = true

      commit(EVENTS_UPDATE, event)
      commit(UI_REMOVE_LOADING_STATE, 'loadingEvent')

      return event.entities[id]
    } catch(e) {
      commit(UI_REMOVE_LOADING_STATE, 'loadingEvent')
      HandleError(new Error(`[action] fetchPerson: ${e}`))
    }
  },
  async searchEvents({ commit, getters }, text) {
    try {
      commit(UI_ADD_LOADING_STATE, 'searchingEvents')

      const events = await eventsApi.find({
        service: getters.getNamedLayer('Master'),
        text,
        fields: EVENTS_FIELDS_TO_SEARCH,
        layers: SUSTAINABILITY_EVENTS_LAYER
      })

      let { entities = {} } = events

      commit(EVENTS_UPDATE, events)
      
      // commit to search happens in store/modules/maps
      // commit(MAP_SEARCH_UPDATE, values(entities))
      commit(UI_REMOVE_LOADING_STATE, 'searchingEvents')

      return events
    } catch(e) {
      commit(UI_REMOVE_LOADING_STATE, 'searchingEvents')
      HandleError(new Error(`[action] searchingEvents: ${e}`))
    }
  }
}

const mutations = {
  [EVENTS_UPDATE](state, { result, entities }) {
    state.result = uniq(state.result.concat(result)).slice()
    state.entities = merge({}, state.entities, entities)
  }
}

export default {
  state,
  getters,
  actions,
  mutations
}