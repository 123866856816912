<template>
  <header :class="getClasses">
    <themes-sidebar :value="true" @input="() => {}"/>

    <!-- TODO: figure out a way to remove these from the DOM -->
    <master-sidebar :value="masterListSidebarOpen"/>
    <measure-sidebar :value="showMeasureSidebar"/>

    <v-toolbar app clipped-left clipped-right :height="showSmallToolbar ? 70 : 100">
      <app-header-logo 
        @click.native="handleLogoClick" 
        v-if="showAppHeaderLogo"/> 

      <div class="app-header__toolbar-right">
        <layer-toggles class="hidden-sm-and-down" v-if="!searchMode"/>

        <!-- only show the icon if not in search mode -->
        <v-btn @click="searchOpen = !searchOpen" icon v-if="!searchMode"><v-icon>search</v-icon></v-btn>

        <!-- if in search mode, show the full search bar -->
        <search-input v-if="searchMode"/>

        <stoplight-menu v-if="!embed || !searchMode"/>
      </div>

      <layer-toggles class="hidden-md-and-up" v-if="!searchMode"/>
    </v-toolbar>

    <search v-model="searchOpen"/>
  </header>
</template>

<script>
  import AppHeaderLogo from './AppHeaderLogo'
  import StoplightMenu from '@/components/StoplightMenu/StoplightMenu'
  import ThemesSidebar from '@/components/_sidebars/ThemesSidebar/ThemesSidebar'
  import MasterSidebar from '@/components/_sidebars/MasterSidebar/MasterSidebar'
  import Search from '@/components/Search/Search'
  import MeasureSidebar from '@/components/_sidebars/MeasureSidebar/MeasureSidebar'
  import LayerToggles from '@/components/LayerToggles/LayerToggles'
  import DisplayManager from '@/mixins/DisplayManager'
  import debounce from 'lodash.debounce'
  import { 
    UI_UPDATE_MAIN_SIDEBAR_OPEN,
    UI_UPDATE_MASTER_LIST_SIDEBAR_OPEN
  } from '@/store/mutation-types'
  import { mapState, mapActions, mapMutations } from 'vuex'

  const SEARCH_DEBOUNCE = 3500

  let AppHeader = {
    name: 'app-header',

    components: {
      AppHeaderLogo,
      ThemesSidebar,
      MasterSidebar,
      Search,
      MeasureSidebar,
      StoplightMenu,
      LayerToggles
    },

    mixins: [DisplayManager],

    data() {
      return {
        initialSearchText: this.$route.params.searchText,
        searchDebounce: debounce(this.initiateSearch, SEARCH_DEBOUNCE),
        searchOpen: false
      }
    },

    computed: {
      ...mapState({
        searchText: state => state.ui.searchText,
        masterListSidebarOpen: state => state.ui.masterListSidebarOpen,
        embed: state => state.ui.embed
      }),
      getClasses() {
        return {
          'app-header': true,
          'app-header--search-open': this.searchMode
        }
      },
      showAppHeaderLogo() {
        if (!this.searchMode) return true
        else if (this.$vuetify.breakpoint.smAndUp) return true

        return false
      },
      showSearchInHeader() {
        return (this.$vuetify.breakpoint.smAndDown)
          ? true
          : (this.searchMode) ? false : true
      },
      showSmallToolbar() {
        return this.$vuetify.breakpoint.mdAndUp || this.searchMode
      }
    },

    methods: {
      ...mapActions(['initiateSearch', 'clearSearch']),
      ...mapMutations({
        updateMainSideBar: UI_UPDATE_MAIN_SIDEBAR_OPEN
      }),
      handleLogoClick() {
        window.location.href = "/"
      },
      handleSearchKeyUp(val) {
        this.searchDebounce.cancel()
        this.initiateSearch(val)
      }
    }
  }

  export default AppHeader
</script>

<style lang="stylus">
  // @import "@/stylus/variables";
   
  .spin-out
    &-leave-active
      position: absolute
   
    &-enter-active, &-leave, &-leave-to
      transition: all .5s ease
      opacity: 1
   
    &-enter, &-leave-to
      transform: rotate(180deg)
      opacity: 0

  .toolbar__content {
    padding: 10px !important;
  }
  
  .app-header {    
    .toolbar {
      background-color: $common-background !important;
      z-index: 5 !important;
    }
    
    .btn .btn__content .icon {
      color: $color-grey;
    }

    .app-header__toolbar-right {
      .search-input {
        width: 400px;
        padding: 0 15px;

        .btn {
          height: 23px;
        }
      }

      @media(max-width: 800px) {
        .search-input {
          width: 100%;
        }
      }
    }
  }
  
  .app-header__toolbar-right {
    display: flex;
    justify-content: flex-end;
    flex: 1;
    align-items: center;
    
    .btn--icon {
      margin: 3px;
    }
  }
  
  .app-header__toolbar {
    align-items: center;
    
    .toolbar__item {
      color: black;
    }
  }
  
  @media (max-width: $md-screen-breakpoint) {
    .app-header--search-open {
      .toolbar__content {
        padding-bottom: 10px !important;
      }
    }

    .toolbar__content {
      padding-bottom: 40px !important;
      flex-wrap: wrap;
      
      .app-header__logo {
        margin-left: 10px !important;
      }
      
      .app-header__toolbar-right {
        margin-right: 0px !important;
      }
    }
    
    .layer-toggles {
      text-align: center;
      width: 100%;
      margin-top: 5px;
      margin-right: 0 !important;
      
      &::-webkit-scrollbar {
        display: none;
      }
      
      -webkit-overflow-scrolling: touch;
      overflow-y: hidden;
      overflow-x: auto;
      flex-wrap: nowrap;
      white-space: nowrap;
    }
  }

  @media(max-width: 350px) {
    .app-header__logo {     
      img {
        height: 25px;
      }
    }
  }
</style>
