export const getHalfOfTopFortyPercent = () => { return ((window.innerHeight * .6)) }

export const createFriendlyURL = (originalString = '', wordLimit = 10) => {
  var scrap = /[`~!@#$%^&*()_|+\=\-?;:'",.<>\{\}\[\]\\\/]/gi

  var lowerNoSpecialChars = originalString.toLowerCase().replace(scrap, '')
  var dividedUpInArray = lowerNoSpecialChars.split(' ', wordLimit);
  var first5SeparatedByDash = dividedUpInArray.join('-');

  // var keep = /[^\w\s]/gi // TODO: example to play with for Craig
  // var removeChars = first5SeparatedByDash.replace(scrap, '')

  return first5SeparatedByDash
}

export const doubleSplit = (str = '', delimiters = []) => {
  if (delimiters.length < 2) throw Error('Must provide two delimiters')

  return str.split(delimiters[0]).map(item => item.split(delimiters[1]))
}

export const appendElipses = str => {
  return (str) ? `${str}... ` : ''
}

export const cullByWordCount = (str) => {
  if (!str) return () => ''
  
  return (wordCount) => {
    let strArray = str.split(' ')
    let innerWordCount = (strArray.length < wordCount) ? strArray.length : wordCount


    return strArray.slice(0, innerWordCount).join(' ')
  }
}
