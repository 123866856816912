export default {
  props: {
    value: {
      type: Boolean,
      default: false
    }
  },
  
  data() {
    return {
      Mixin_active: this.value,
    }
  },

  watch: {
    value(newValue) {
      this.Mixin_active = newValue
    },
    Mixin_active() {
      this.$emit('input', this.Mixin_active)
    }
  }
}
