<template>
  <div :class="getClasses" v-resize="handleResize">
    <v-navigation-drawer 
      v-model="Mixin_active" 
      clipped
      hide-overlay
      right
      app
      stateless
      v-if="showDrawer"
      :key="keyOverride || $route.name"
      :width="navWidth"
      class="dynamic-panel__sidebar">

      <div class="dynamic-panel__sidebar-content-wrapper">

        <slot name="controls">
          <div class="dynamic-panel__controls">
            <slot name="back">
              <span @click="handleBack" role="button" class="dynamic-panel__back">Back</span>
              <!-- <v-btn icon @click="handleBack" class="dynamic-panel__back">
                <v-icon>chevron_left</v-icon>
              </v-btn> -->
            </slot>
            <slot name="close">
              <v-btn icon @click="$emit('close')" class="dynamic-panel__close">
                <v-icon>close</v-icon>
              </v-btn>
            </slot>
          </div>
        </slot>
        
        <div class="dynamic-panel__sidebar-content">
          <dynamic-panel-loading v-if="loading" v-once/>

          <!-- main content rendered here -->
          <slot v-else></slot>
        </div>
      </div>
    </v-navigation-drawer>

    <info-panel v-else
      v-model="Mixin_active" 
      :status="infoPanelStatus" 
      @close="$emit('close')" 
      @back="handleBack"
      :loading="loading">

      <div slot="header" class="dynamic-panel__info-panel-header">
        <!-- the contents of this slot get passed to the InfoPanel.$slots.header -->
        <slot name="panel-header"></slot>
      </div>

      <div slot="loading" class="dynamic-panel__info-panel-content">
        <dynamic-panel-loading v-once/>
      </div>

      <div slot="content" class="dynamic-panel__info-panel-content">
        <slot></slot>
      </div>

      <div slot="activator" class="dynamic-panel__info-panel-activator" v-if="$slots['panel-activator']">
        <slot name="panel-activator"></slot>
      </div>

      <slot name="controls">
        <div slot="back" v-if="$slots['back']">
          <slot name="back"></slot>
        </div>

        <div slot="close" v-if="$slots['close']">
          <slot name="close"></slot>
        </div>
      </slot>
    </info-panel>
  </div>
</template>

<script>
  // TODO: don't use CSS, use a screen resize, requestFrameAnimation, to determine which component
  import DynamicPanelLoading from './DynamicPanelLoading'
  import ExternalBind from '@/mixins/ExternalBind'

  let DynamicPanel = {
    components: {
      DynamicPanelLoading
    },

    mixins: [ExternalBind],

    props: {
      infoPanelStatus: {
        type: String,
        default: 'preview'
      },
      loading: Boolean,
      keyOverride: String,
      navWidth: {
        type: [String, Number],
        default: 340
      },
      back: Boolean,
      close: {
        type: Boolean,
        default: true
      }
    },

    data() {
      return {
        initialWindowWidth: window.innerWidth
      }
    },

    computed: {
      getClasses() {
        return {
          'dynamic-panel': true,
          'dynamic-panel--hide-back': !this.back,
          'dynamic-panel--hide-close': !this.close
        }
      },
      showDrawer() {
        // return true if screen size if larger than
        return this.$vuetify.breakpoint.mdAndUp
      }
    },

    methods: {
      handleResize() {
        this.initialWindowWidth = window.innerWidth
      },
      handleBack() {
        this.$router.go(-1)
        this.$emit('back')
      }
    }
  }

  export default DynamicPanel
</script>

<style lang="stylus">
  // @import "@/stylus/variables";
  
  .dynamic-panel {
    .navigation-drawer--close {
      box-shadow: none !important;
    }
    
    .navigation-drawer {
      overflow: visible !important;
      padding-bottom: 0;
      z-index: 3 !important;
    }
    
    .dynamic-panel__info-panel-content {
      height: 100%;
      max-width: 600px;
      margin: 0 auto;
    }
    
    .dynamic-panel__sidebar-close {
      background-color: $color-dark-grey;
      position: absolute;
      left: -30px;
      bottom: 30px;
    }

    .dynamic-panel__sidebar-content-wrapper {
      display: flex;
      flex-direction: column;
      background-color: $common-background;
      height: 100%;
      padding: 0 0px 5px;
    }
    
    .dynamic-panel__controls {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
    }

    .dynamic-panel__sidebar {
      box-shadow: 0px -1px 6px 2px rgba(#000, 0.2);
    }
    
    .dynamic-panel__sidebar-content {
      height: 100%;
      overflow: auto;
      -webkit-overflow-scrolling: touch;
    }

    .dynamic-panel__back,
    .info-panel__back {
      line-height: 1em;
      padding: 15px;
      text-transform: uppercase;
      color: $color-grey;
      cursor: pointer;

      &:hover {
        color: $color-dark-grey;
      }
    }
    
    .dynamic-panel__info-panel-header {
      margin-top: 10px;
    }

    .info-panel, .info-panel__header {
      background-color: $color-light-grey !important;
    }

    .info-panel__activator {
      margin-top: -50px;
      background-color: $color-dark-grey !important;
      
      .icon {
        color: $common-background !important;
      }
    }

    .dynamic-panel__controls {
      padding: 10px 0;
      display: flex;
      justify-content: space-between;
      align-items: center;
    }
  }
  
  .dynamic-panel--hide-back {
    .info-panel__back,
    .dynamic-panel__back {
      opacity: 0;
      pointer-events: none;
    }
  }
  
  .dynamic-panel--hide-close {
    .info-panel__close,
    .dynamic-panel__close {
      opacity: 0;
      pointer-events: none;
    }
  }
</style>
