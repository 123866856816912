import store from '@/store'
import notificationsApi from '@/api/notifications'
import { HandleError } from '@/utils/errorHandling'

const state = {
  data: {
    subject: 'Submission from Sustainability Map Feedback Form',
    replyToName: 'UA Sustainability Map Feedback',
    replyTo: 'donotreply@cals.arizona.edu',
  }
}

const getters = {}

const actions = {
  async notifyFeedback({ dispatch }, payload) {
    try {
      let data = Object.assign({}, state.data, payload)

      await notificationsApi.post(data)

      dispatch('generalEvent', { category: 'feedback', action: 'submit' })
    } catch(e) {
      HandleError(new Error(`[actions] notifyFeedback: ${e}`))
    }
  }
}

const mutations = {}

export default {
  state,
  getters,
  actions,
  mutations
}