export const ACTIVITY_POINTS_UNFILTERED_LAYER = 119
export const SUSTAINABILITY_PEOPLE_LAYER = 123
export const ACTIVITY_PEOPLE_RELATED_TABLE = 121
export const SUSTAINABILITY_NEWS_LAYER = 125
export const SUSTAINABILITY_EVENTS_LAYER = 126

export const GREY_BASEMAP_TILES = 'https://services.arcgisonline.com/ArcGIS/rest/services/Canvas/World_Light_Gray_Base/MapServer'
export const SUSTAINABILITY_TILES_URL = 'https://services.maps.arizona.edu/pdc/rest/services/SubtleCanvasTiles/MapServer'
export const SUSTAINABILITY_MAP_SERVER_URL = 'https://services.maps.arizona.edu/pdc/rest/services/SustainabilityDynamic/MapServer'

export const ACTIVITY_FETCH_INDEX_FIELDS = [
  'ActivityStatus',
  'EGISID',
  'Label', // clones this property to Name in normalizer
  'OBJECTID',
  'Summary',
  'SustainabilityTheme',
  'SustainabilityTheme2',
  'SustainabilityType'
]

export const ACTIVITY_FETCH_DETAIL_FIELDS = ACTIVITY_FETCH_INDEX_FIELDS.concat([
  'AdditionalParticipants',
  'Department',
  'DepartmentURL',
  'Description',
  'Location',
  'Photo',
  'OtherGroupsParticipating',
  'URL'
])

export const PEOPLE_FETCH_INDEX_FIELDS = [
  'AffDept',
  'Full_Name', // clones this property to Name in normalizer
  'OBJECTID',
  'PeopleID',
  'PeopleStatus',
  'Photo',
  'SustainabilityTheme',
  'SustainabilityTheme2',
  'SustainabilityTheme3'
]

export const PEOPLE_PHOTO_BASE_URL = '//maps.arizona.edu/Photos/Sustainability'
export const PEOPLE_FETCH_DETAIL_FIELDS = PEOPLE_FETCH_INDEX_FIELDS.concat([
  'AffDeptURL',
  'BackgroundPhoto',
  'DepartmentEmail',
  'Phone'
])

export const NEWS_FETCH_INDEX_FIELDS = [
  'Field_pub_date',
  'Field_url_link',
  'ID',
  'News_Thumb_Image',
  'OBJECTID',
  'SustainabilityTheme',
  'Title'
]

export const NEWS_FETCH_DETAILS_FIELDS = NEWS_FETCH_INDEX_FIELDS.concat([
  'Field_news_publisher',
  'Field_related_profile',
])

export const EVENTS_FETCH_INDEX_FIELDS = [
  'Event_calendar_date',
  'Event_Date',
  'Field_speaker',
  'Field_off_campus_location',
  'Field_room',
  'ID',
  'OBJECTID',
  'Path',
  'SustainabilityTheme',
  'Title'
]

export const EVENTS_FETCH_DETAILS_FIELDS = EVENTS_FETCH_INDEX_FIELDS.concat([
  'Body',
  'Field_event_image'
])

export const ACTIVITY_FIELDS_TO_SEARCH = ['EGISID', 'Label', 'Description', 'Keywords', 'SustainabilityTheme', 'SustainabilityTheme2', 'AdditionalParticipants']
export const PEOPLE_FIELDS_TO_SEARCH = ['PeopleID', 'AffDept', 'Full_Name', 'DepartmentEmail', 'SustainabilityTheme', 'SustainabilityTheme2', 'SustainabilityTheme3']
export const NEWS_FIELDS_TO_SEARCH = ['Field_news_publisher', 'Field_related_profile', 'Field_research_themes', 'Field_url_link', 'Title']
export const EVENTS_FIELDS_TO_SEARCH = ['Field_off_campus_location', 'Field_research_themes', 'Field_speaker', 'Field_other_speakers', 'Field_series_name', 'Path', 'Title']

export const STATUS_ACTIVE_VALUES = ['active', 'in-progress', 'planned', 'ongoing', 'current employee', 'current student']
