<template>
  <list-item class="event-item" 
    @click="$router.push(getEventRouteConfig(item.EventID))"
    :key="item.EventID">
    <list-item-content>
      <list-item-title>
        {{ item.Name }} <span class="event-item__speaker" v-if="item.Field_speaker">- {{ item.Field_speaker }}</span>
      </list-item-title>
      <list-item-subtitle>{{ item.Field_off_campus_location }}</list-item-subtitle>

      <div class="list-item__attributes">
        <pill>Events</pill>
        <theme-circles :themes="[item.SustainabilityTheme]"/>
        <div class="event-item__external">
          <v-btn small icon @click.stop="handleExternalClick"><v-icon>open_in_new</v-icon></v-btn>
        </div>
      </div>  
    </list-item-content>

    <list-item-avatar>
      <div class="event-item__calendar">
        <span class="event-item__calendar-day">{{ getDay }}</span>
        <span class="event-item__calendar-month">{{ getMonth.toUpperCase() }}</span>
      </div>
      <div class="event-item__calendar-time">{{ getTime }}</div>
    </list-item-avatar>
  </list-item>
</template>

<script>
  import Leaflet from 'leaflet'
  import ThemeCircles from '@/components/ThemeCircles/ThemeCircles'
  import { mapGetters } from 'vuex'
  import { getHalfOfTopFortyPercent } from '@/utils/generalUtils'
  import moment from 'moment'

  let EventItem = {
    components: {
      ThemeCircles
    },

    props: {
      item: {
        type: Object,
        required: true,
        default: () => {
          return {}
        }
      }
    },

    computed: {
      getCalendarDate() {
        return moment(this.item.Event_calendar_date).format('MMM DD, YYYY h:mma')
      },
      getMonth() {
        return moment(this.item.Event_calendar_date).format('MMM')
      },
      getDay() {
        return moment(this.item.Event_calendar_date).format('DD')
      },
      getTime() {
        return moment(this.item.Event_calendar_date).format('h:mma')
      },
      ...mapGetters(['getEventById', 'getMap', 'getEventRouteConfig'])
    },

    methods: {
      handlePinClick() {
        let layerBounds = Leaflet.geoJSON(this.getEventById(this.item.EventID)).getBounds()

        console.log(layerBounds)
        this.getMap('mainMap').fitBounds(layerBounds, {
          paddingTopLeft: [0, -(getHalfOfTopFortyPercent())],
          maxZoom: 15
        })
      },
      handleExternalClick() {
        window.open(this.item.Path)
      }
    }
  }

  export default EventItem
</script>

<style lang="stylus">
  // @import "@/stylus/variables"
    
  .event-item__attributes {
    display: flex;
    flex-direction: row;
    align-items: center;
  }

  .event-item__external {
    margin-left: 5px;

    .icon {
      font-size: 18px;
    }

    .btn--icon {
      margin: 0;
    }
  }

  .list-item__title .event-item__speaker {
    color: $common-text;
    font-size: 16px
    font-weight: normal
  }

  .event-item__calendar {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;    
    width: 50px;
    height: 50px;
    border-radius: 10px;
    background-color: $color-dark-grey;
    color: #fff;
    padding: 5px;

    .event-item__calendar-day {
      font-size: 30px;
      line-height: .8em;
    }
    
    .event-item__calendar-month {
      font-size: 16px;
      line-height: .8em;
    }
  }

  .event-item__calendar-time {
    font-size: 14px;
    text-align: center;
    text-transform: uppercase;
    margin-top: 3px;
  }
</style>
