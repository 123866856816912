let ListItem = {
  name: 'list-item',
  functional: true,
  render(createEl, { data, children }) {
    data.class = { 'list-item': true }

    return createEl('li', data, children)
  }
}

export default ListItem