import { query as esriQuery } from 'esri-leaflet'
import mapsApi, { negotiateResponse } from './maps'
import { normalizePeopleCollection } from './people'
import { SUSTAINABILITY_MAP_SERVER_URL, ACTIVITY_PEOPLE_RELATED_TABLE } from '@/utils/constants'
import reduce from 'lodash.reduce'
import find from 'lodash.find'
import mapkeys from 'lodash.mapkeys'
import clonedeep from 'lodash.clonedeep'

export function normializeRelatedActivites(collection = [], fullResult) {
  try {
    let result = []

    let entities = reduce(collection, (outcome, feature) => {
      let properties = mapkeys(feature.properties, function(value, key) {
        // TODO: check if key == 'SA_OBJECT' and return it!

        /** 
          TODO: WARNING this is currently returning

          PeopleActivitiesRelate.OBJECTID: 3169
          PeopleActivitiesRelate.PeopleID: 123
          PeopleActivitiesRelate.SA_EGISID: 7279
          SustainabilityPeoplePoints.OBJECTID: 8490

          which means SustainabilityPeoplePoints is overriding PeopleActivitiesRelate
         */
        let keyValueArray = key.split('.')
        return keyValueArray[keyValueArray.length - 1]
      })

      feature.properties = properties

      let { 
        properties: {
          SA_EGISID
        } = {}
      } = feature

      if (!SA_EGISID) console.error("[normializeRelatedActivites]", ': Feature in payload did not contain SA_EGISID')

      outcome[SA_EGISID] = Object.assign({}, feature)
      result.push(SA_EGISID)

      return outcome
    }, {})

    return { result, entities }
  } catch(e) {
    throw new Error(`normializeRelatedActivites: ${e}`)
  }
}

let relatedApi = {
  get(field, id) {
    if (!field || !id) throw new Error('[api] relatedApi.get: no field or id parameter')
    let query = esriQuery({ url: `${SUSTAINABILITY_MAP_SERVER_URL}/${ACTIVITY_PEOPLE_RELATED_TABLE}` })

    return new Promise((resolve, reject) => {
      query.where(`${field}=${id}`).run((error, featureCollection, response) => {
        negotiateResponse(error, featureCollection, response)
          .then(({ features, results }) => resolve(features, results))
          .catch(reject)
      })
    })
  },
  getPeopleByActivity(activityId) {
    if (!activityId) throw new Error('[api] relatedApi.getPeopleByActivity: no id parameter')

    // related table (202) has same attributes as people table (203) so we can repurpose `normalizePeopelCollection`
    return this.get('PeopleActivitiesRelate.SA_EGISID', activityId).then(normalizePeopleCollection)
  },
  getActivitiesByPeople(peopleId) {
    if (!peopleId) throw new Error('[api] relatedApi.getActivitiesByPeople: no id parameter')

    return this.get('PeopleActivitiesRelate.PeopleID', peopleId).then(normializeRelatedActivites)
  }
}

export default relatedApi