<template>
  <div :class="getClasses">
    <div class="filter-list__collapse-wrapper">
      <collapse class="filter-list__collapse" ref="collapse" v-if="filterSort" v-model="watchCollapse">
        <div slot="trigger" class="filter-list__collapse-trigger">
          <div class="filter-list__title" v-if="$slots['title']">
            <slot name="title"></slot>
          </div>

          <span class="filter-list__icon-label">Filter</span>
          <v-btn icon><v-icon>filter_list</v-icon></v-btn>
        </div>

        <div slot="content">
          <v-text-field
            class="filter-list__search"
            @input="filterDebounce"
            :label="searchLabel"
            ref="filterInput"/>

          <div class="filter-list__options">
            <slot name="menu" v-if="$slots['menu']"></slot>
          </div>
        </div>
      </collapse>
    </div>

    <template v-if="loading">
      <v-progress-linear v-bind:indeterminate="true" color="grey"></v-progress-linear>

      <ul class="filter-list__list">
        <list-item v-for="num in 7" :key="num">
          <list-item-loading/>
        </list-item>
      </ul>
    </template>

    <ul v-if="groupBy" 
      class="filter-list__list" 
      v-infinite-scroll="loadMore" 
      infinite-scroll-distance="50" 
      :infinite-scroll-disabled="loading"
      :infinite-scroll-immediate-check="false"
      v-show="!loading">
      <template v-for="(group, key) in groupedItems">
        <slot name="group-headings" :heading="key"></slot>
        <slot name="list"
          v-for="item in group"
          :item="item">
        </slot>
      </template>

      <list-item v-if="isEmpty(groupedItems)" class="filter-list__list-empty">
        <slot name="empty-item">
          <list-item-content>
            <list-item-title>Sorry!</list-item-title>
            <list-item-description>No items in this list matched your search.</list-item-description>
          </list-item-content>
        </slot>
      </list-item>
    </ul>

    <ul v-else 
      class="filter-list__list" 
      v-infinite-scroll="loadMore" 
      infinite-scroll-distance="50" 
      :infinite-scroll-disabled="loading"
      :infinite-scroll-immediate-check="false"
      v-show="!loading">
      <slot name="list"
        v-for="item in filteredItems"
        :item="item">
      </slot>

      <list-item v-if="!filteredItems.length" class="filter-list__list-empty">
        <slot name="empty-item">
          <list-item-content>
            <list-item-title>Sorry!</list-item-title>
            <list-item-description>No items in this list matched your search.</list-item-description>
          </list-item-content>
        </slot>
      </list-item>
    </ul>
  </div>
</template>

<script>
  import debounce from 'lodash.debounce'
  import filter from 'lodash.filter'
  import values from 'lodash.values'
  import groupBy from 'lodash.groupby'
  import isEmpty from 'lodash.isempty'
  import map from 'lodash.map'
  import reduce from 'lodash.reduce'

  let FilterList = {
    components: {},

    mounted() {
      if (this.filterSort && !this.$slots['menu']) {
        console.warn('[FilterList] warning: filterSort is enabled but no menu slot found')
      }
    },

    props: {
      items: {
        type: Array,
        default() {
          return []
        },
        required: true
      },
      title: String,
      searchLabel: String,
      loading: Boolean,
      itemsPerPage: {
        type: [Number, String],
        default: 10
      },
      filterSort: {
        type: Boolean,
        default: false
      },
      quickFilterFieldName: String, // TODO: not in use right now
      quickFilterPredicates: {
        type: Array,
        default: () => {
          return []
        }
      },
      sortBy: {
        type: Object,
        default: () => {
          return {
            field: 'Name',
            order: 'asc'
          }
        }
      },
      groupBy: {
        type: [String, Boolean],
        default: false
      }
    },

    watch: {
      filterValue() {
        this.page = 1
      },
      watchCollapse(val) {
        if (val) {
          setTimeout(() => {
            this.$refs.filterInput.$refs.input.focus()
            this.$refs.filterInput.$el.click()
          }, 650)
        }
      }
    },

    data() {
      return {
        filterValue: '',
        filterDebounce: debounce(this.handleFilter, 500),
        page: 1,
        isEmpty,
        watchCollapse: false
      }
    },

    computed: {
      sortedItems() {
        let { field, order } = this.sortBy
        let innerItems = this.items.slice()

        // TODO: this sort is wrong, see SortableTable in MRL
        return innerItems.sort((a, b) => {
          return (a[field] > b[field])
            ? (order === 'asc') ? 1 : -1
            : (order === 'asc') ? -1 : 1
        })
      },
      filteredItems() {
        return this.filterItems(this.sortedItems).slice(0, this.itemsCount)
      },
      groupedItems() {
        const groups = groupBy(this.filterItems(this.sortedItems), this.groupBy)

        const slicedGroups = reduce(groups, (outcome, group) => {
          return (Array.isArray(group)) ? outcome.concat(group) : outcome
        }, []).slice(0, this.itemsCount)

        return groupBy(slicedGroups, this.groupBy)

        // return groupBy(this.filteredItems, this.groupBy)
      },
      itemsCount() {
        const count = this.page * this.itemsPerPage

        return (count >= this.sortedItems.length) 
          ? this.sortedItems.length
          : this.page * this.itemsPerPage
      },
      getClasses() {
        return {
          'filter-list': true,
          'filter-list--with-menu': this.filterSort
        }
      }
    },

    methods: {
      handleFilter(val) {
        this.filterValue = val.trim().toLowerCase()
      },
      loadMore() {
        this.page = this.page + 1
      },
      filterItems(items) {
        return filter(items, (item) => {
          let searchMe = values(item).toString().toLowerCase()

          let arrayOfPassingFieldConditions = map(this.quickFilterPredicates, (value) => {
            return (typeof value === 'function')
              ? value(item)
              : true
          })

          /**
            This could be a version if we wanted to pass in field columns

            let arrayOfPassingFieldConditions = map(this.quickFilterValues, (value) => {
              return (typeof value === 'string')
                ? !!(item[this.quickFilterFieldName].toLowerCase() === value.toLowerCase())
                : value(item[this.quickFilterFieldName])
            })
           */

          let meetsConditions = (this.quickFilterPredicates.length)
            ? arrayOfPassingFieldConditions.includes(true)
            : true

          return searchMe.includes(this.filterValue) && meetsConditions
        })
      }
    }
  }

  export default FilterList
</script>

<style lang="stylus">
  // @import "@/stylus/variables";
  
  .filter-list {
    display: flex;
    flex-direction: column;
    flex: 1;
    height: 100%;
    
    .progress-linear {
      margin: 0;
    }
    
    .btn .btn__content .icon {
      color: $color-dark-grey;
    }
    
    .filter-list__title {
      text-transform: uppercase;
      margin-right: auto;
      font-size: 16px;
      color: $color-grey;
    }
  }

  .filter-list__collapse-wrapper {
    position: relative;
    border-bottom: 2px solid rgba($color-grey, 0.3);
  }

  .filter-list__icon-label {
    text-transform: uppercase;
    font-size: 12px;
    margin-right: 5px;
    color: $color-grey;
  }

  .filter-list__collapse-trigger {
    padding: 15px;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    
    .btn--icon {
      margin: 0;
      .icon {
        color: $color-grey
      }
    }
  }
  
  .filter-list__collapse {
    width: 100%;
    
    .input-group__details {
      height: 0;
      min-height: 0;
    }

    .subheader {
      padding: 5px 0 0 ;
      height: auto;
    }
    
    .radio-group {
      padding: 0;
    }
    
    .collapse__content {
      padding: 15px;
    }
  }
  
  .filter-list__options {
    padding: 10px;
  }
  
  .filter-list__list {
    overflow: auto;
    -webkit-overflow-scrolling: touch;
    flex: 1;
    list-style: none;
    margin: 0;
    padding: 0;
  }
  
  .filter-list__list-empty {
    pointer-events: none;
  }
</style>