<template>
  <div>
    <dynamic-panel v-model="DynamicPanelContainerMixin_active"
      info-panel-status="half"
      key-override="master"
      @close="handleClose"
      :nav-width="masterListSidebarWidth"
      class="master-sidebar">
      <span slot="controls"></span>
      <span slot="back"></span>
      <template>
        <master-list v-if="DynamicPanelContainerMixin_active"/>
        <!-- this only appears on larger screens, when master list is closed -->
        <div class="master-list__tab hidden-sm-and-down" @click="handleMasterListToggle" role="button" v-else>
          <v-icon medium>chevron_left</v-icon>
        </div>
      </template>
    </dynamic-panel>

    <!-- this only appears on smaller screens, when master panel is closed -->
    <div :class="getTabClasses" @click="handleMasterListToggle" role="button">
      <v-icon medium>{{ DynamicPanelContainerMixin_active ? 'chevron_right' : 'chevron_left' }}</v-icon>
    </div>
  </div>
</template>

<script>
  import MasterList from '@/components/_lists/MasterList/MasterList'
  import DisplayManager from '@/mixins/DisplayManager'
  import DynamicPanelContainerMixin from '@/mixins/DynamicPanelContainerMixin'
  import { UI_UPDATE_MASTER_LIST_SIDEBAR_OPEN } from '@/store/mutation-types'
  import { mapMutations, mapState, mapActions } from 'vuex'

  let MasterSidebar = {
    name: 'master-sidebar',

    components: {
      MasterList
    },

    watch: {
      DynamicPanelContainerMixin_active(val) {
        if (val) {
          this.panelClosed = false
        } else {
          setTimeout(() => {
            this.panelClosed = true
          }, 500)
        }
      },
      searchMode(val) {
        if (val) this.toggleMasterListSidebar(true)
      }
    },

    mixins: [DynamicPanelContainerMixin, DisplayManager],

    data() {
      return {
        panelClosed: true
      }
    },

    computed: {
      ...mapState({
        masterListSidebarWidth: state => state.ui.masterListSidebarWidth
      }),
      getTabClasses() {
        return {
          'master-list__tab': true,
          'hidden-md-and-up': true,
          'master-list__tab--open': !this.panelClosed,
        }
      }
    },

    methods: {
      ...mapMutations({
        toggleMasterListSidebar: UI_UPDATE_MASTER_LIST_SIDEBAR_OPEN
      }),
      ...mapActions(['clearSearch']),
      handleClose() {
        this.DynamicPanelContainerMixin_handleClose()

        setTimeout(() => {
          this.toggleMasterListSidebar(false)
        }, (1000 * 0.40))
      },
      handleMasterListToggle() {
        this.DynamicPanelContainerMixin_active
          ? this.handleClose()
          : this.toggleMasterListSidebar(true)
      }
    }
  }

  export default MasterSidebar
</script>

<style lang="stylus">
  // @import "@/stylus/variables";

  .master-sidebar {
    position: relative;
  }

  .master-sidebar__search-stats {
    padding: 5px 15px 15px;
    font-size: 14px;
    line-height: 1em;
    display: flex;
    justify-content: space-between;
    align-items: center;
    color: #fff;
    background-color: $color-dark-grey;
  }

  .master-list__tab {
    position: absolute;
    display: flex;
    justify-content: center;
    top: 10px;
    left: -28px;
    width: 30px;
    height: 50px;
    background-color: $color-dark-grey;
    padding: 5px 1px;
    cursor: pointer;
    z-index: 3;
    box-shadow: -3px 0px 3px 0px rgba(#000, 0.2);

    .icon {
      color: $color-light-grey !important;
    }
  }

  @media(max-width: $md-screen-breakpoint) {
    .master-list__tab {
      top: auto;
      bottom: 0;
      left: 50vw;
      width: 75px;
      height: 25px;
      margin-left: -34px;
      opacity: 1;
      transition: all .25s ease;

      .icon {
        transform: rotate(90deg);
      }
    }

    .master-list__tab--open {
      bottom: -75px;
      opacity: 0;
    }
  }
</style>
