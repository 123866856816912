import { query as esriQuery } from 'esri-leaflet'
import reduce from 'lodash.reduce'
import find from 'lodash.find'
import mapsApi, { negotiateResponse } from './maps'
import {
  SUSTAINABILITY_MAP_SERVER_URL,
  ACTIVITY_POINTS_UNFILTERED_LAYER
} from '@/utils/constants'
/**
 * Normalize a collection of GeoJSON features into result and entities and re-project lat/lng
 *
 * @param {array} collection GeoJSON Feature Collection 
 * @returns {Object} { results: [123, 456], entities: { 123: ..., 456: ... } }
 */
const ActivityPointsUnfiltered = () => esriQuery({ url: `${SUSTAINABILITY_MAP_SERVER_URL}/${ACTIVITY_POINTS_UNFILTERED_LAYER}` })

export function normalizeActivityCollection(collection = [], fullResult) {
  try {
    let result = []

    let entities = reduce(collection, (outcome, feature) => {
      let { 
        id,
        properties: {
          OBJECTID,
          OBJECTID_1,
          EGISID,
          Label,
          Name,
          ActivityStatus
        } = {}
      } = feature

      if (!EGISID) console.info("[normalizeActivityCollection]", id, OBJECTID, OBJECTID_1, ': Feature in payload did not contain EGISID')
      if (EGISID == 'Null') throw Error(`[normalizeActivityCollection]: Feature EGISID was a "Null" value`)

      let useId = String(EGISID || id || OBJECTID || OBJECTID_1)

      feature.relatedPeople = []
      feature.properties.isActivity = true
      feature.properties.Name = Label || Name
      feature.properties.Status = ActivityStatus

      outcome[useId] = Object.assign({}, feature)
      result.push(useId)

      return outcome
    }, {})

    return { result, entities }
  } catch(e) {
    throw new Error(`normalizeActivityCollection: ${e}`)
  }
}

const activitiesApi = {
  // query against the entire MapService
  find({ service, text, fields = '*', layers }) {
    return new Promise((resolve, reject) => {
      mapsApi.find({ service, text, fields, layers })
        .then(({ features, results }) => resolve(normalizeActivityCollection(features, results)))
        .catch(reject)
    })
  },
  // query against the entire MapService
  identify({ geometry, service, map, layers }) {
    return new Promise((resolve, reject) => {
      mapsApi.identify({ geometry, service, map, layers })
        .then(({ features, results }) => resolve(normalizeActivityCollection(features, results)))
        .catch(reject)
    })
  },
  // query against the ActivityPoints UnfilteredLayer
  get(id, fields = []) {
    if (!id) throw new Error('[api] activities get: no id parameter')
    const query = ActivityPointsUnfiltered()

    if (fields.length) query.fields(fields)

    return new Promise((resolve, reject) => {
      query.where(`EGISID=${id}`).run((error, featureCollection, response) => {
        negotiateResponse(error, featureCollection, response)
          .then(({ features, results }) => resolve(normalizeActivityCollection(features, results)))
          .catch(reject)
      })
    })
  },
  getWhere(sql, fields = []) {
    if (!sql) throw new Error('[api] activities getWhere: no sql parameter')
    const query = ActivityPointsUnfiltered()

    if (fields.length) query.fields(fields)

    return new Promise((resolve, reject) => {
      query.where(sql).run((error, featureCollection, response) => {
        negotiateResponse(error, featureCollection, response)
          .then(({ features, results }) => resolve(normalizeActivityCollection(features, results)))
          .catch(reject)
      })
    })
  },
  getByIds(ids, fields = []) {
    if (!ids || !Array.isArray(ids)) throw new Error('[api] activities getByIds: no id parameter')
    const query = ActivityPointsUnfiltered()

    if (fields.length) query.fields(fields)

    return new Promise((resolve, reject) => {
      query.ids(ids).run((error, featureCollection, response) => {
        negotiateResponse(error, featureCollection, response)
          .then(({ features, results }) => resolve(normalizeActivityCollection(features, results)))
          .catch(reject)
      })
    })
  }
}

export default activitiesApi
