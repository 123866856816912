<template>
  <v-toolbar-title class="app-header-logo">
    <img src="/static/img/ua_block_rgb_3.png" alt="University of Arizona"/>
    Sustainability Map
  </v-toolbar-title>
</template>

<script>
  export default {
    name: 'app-header-logo',
  }
</script>

<style lang="stylus">
  // @import "@/stylus/variables"

  .app-header-logo
    display: flex
    align-items: center
    margin: 0px 15px
    cursor: pointer
    position: relative
    top: -3px
    
    img
      height: 35px
      margin-right: 5px
  
  @media(max-width: $sm-screen-breakpoint)
    .app-header-logo
      margin-left: 0 !important

  @media(max-width: 320px)
    .app-header-logo
      img
        height: 20px
</style>