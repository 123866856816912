let Circle = {
  name: 'c-circle',
  functional: true,
  // this will be converted into 20px width and height
  props: {
    size: {
      type: [Number, String],
      default: 20
    },
    color: {
      type: String,
      default: 'green'
    },
    outline: Boolean
  },

  render(createEl, { data, props, children }) {
    data.class = {
      'circle': true,
      [`circle--${props.color}`]: true,
      'circle--outline': props.outline
    }

    data.style = {
      width: `${props.size}px`,
      height: `${props.size}px`,
      borderRadius: '50%'
    }

    return createEl('span', data)
  }
}

export default Circle