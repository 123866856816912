import CCircle from '@/components/_ui/Circle/Circle'
import Collapse from '@/components/_ui/Collapse/Collapse'
import DynamicPanel from '@/components/_ui/DynamicPanel/DynamicPanel'
import FilterList from '@/components/_ui/FilterList/FilterList'
import FullPage from '@/components/_ui/FullPage/FullPage'
import IconMoon from '@/components/_ui/IconMoon/IconMoon'
import IconTheme from '@/components/_ui/IconTheme/IconTheme'
import InfoPanel from '@/components/_ui/InfoPanel/InfoPanel'
import ListItem from '@/components/_ui/ListItem/ListItem'
import ListItemContent from '@/components/_ui/ListItem/ListItemContent'
import ListItemTitle from '@/components/_ui/ListItem/ListItemTitle'
import ListItemSubtitle from '@/components/_ui/ListItem/ListItemSubtitle'
import ListItemDescription from '@/components/_ui/ListItem/ListItemDescription'
import ListItemAvatar from '@/components/_ui/ListItem/ListItemAvatar'
import ListItemLoading from '@/components/_ui/ListItem/ListItemLoading'
import LoadingBlock from '@/components/_ui/Loading/LoadingBlock'
import Pill from '@/components/_ui/Pill/Pill'
import SearchInput from '@/components/_ui/SearchInput/SearchInput'

export default {
  CCircle,
  Collapse,
  DynamicPanel,
  FilterList,
  FullPage,
  IconMoon,
  IconTheme,
  InfoPanel,
  ListItem,
  ListItemContent,
  ListItemTitle,
  ListItemSubtitle,
  ListItemDescription,
  ListItemAvatar,
  ListItemLoading,
  LoadingBlock,
  Pill,
  SearchInput
}
