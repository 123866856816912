<template>
  <span :class="classObject" :style="styleObject" role="presentation"></span>
</template>

<script>
  let IconMoon = {
    components: {},

    props: {
      name: {
        type: String,
        required: true
      },
      size: {
        type: Number,
        default: 40
      },
      fill: String
    },

    data() {
      return {}
    },

    computed: {
      styleObject() {
        return {
          fontSize: `${this.size}px`
        }
      },
      classObject() {
        return {
          iconmoon: true,
          [`iconmoon-${this.name}`]: true
        }
      }
    }
  }

  export default IconMoon
</script>

<style lang="stylus">
  // @import "@/stylus/variables";
  
  .iconmoon {
    color: $color-light-grey;
  }
</style>