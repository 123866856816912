<template>
  <div :class="getCollapseClasses">
    <div @click.prevent="clickHandler" class="collapse__trigger" role="button">
      <slot name="trigger"></slot>
    </div>
    <div class="collapse__content-wrapper" ref="contentWrapper" :style="contentWrapperStyles" @click="captureClicks">
      <div ref="content" class="collapse__content">
        <slot name="content"></slot>
      </div>
    </div>
  </div>
</template>

<script>
  import ExternalBind from '@/mixins/ExternalBind'
  import shortid from 'shortid'

  let Collapse = {
    mixins: [ExternalBind],

    data() {
      return {
        open: false,
        shortId: shortid.generate()
      }
    },

    mounted() {
      // if default state is "open", then force technique to open to full height of refs.content
      if (this.Mixin_active) {
        this.$nextTick(() => {
          this.shortId = shortid.generate()
          this.$refs.contentWrapper.click()
        })
      }
    },

    methods: {
      clickHandler(e) {
        this.Mixin_active = !this.Mixin_active
      },
      captureClicks(e) {
        // anytime the body gets clicked on, it will generate a new shortid
        this.$nextTick(() => {
          this.shortId = shortid.generate()
        })
      }
    },

    computed: {
      contentHeight() {
        let {
          $refs: {
            content: {
              offsetHeight
            } = {}
          } = {}
        } = this
        // when this.shortId changes, it will re-evaluate content.offsetHeight
        return `${offsetHeight}px-${this.shortId}`
      },
      getCollapseClasses() {
        return {
          'collapse': true,
          'collapse--open': this.Mixin_active
        }
      },
      contentWrapperStyles() {
        return {
          height: (this.Mixin_active) ? this.contentHeight.split('-')[0] : '0px'
        }
      }
    }
  }

  export default Collapse
</script>

<style lang="stylus">
  .collapse {
    position: relative;
  }

  .collapse__trigger {
    position: relative;
    cursor: pointer;
  }

  .collapse__content-wrapper {
    height: 0;
    overflow: hidden;
    transition: all .5s ease;
  }

  .collapse__content {
    position: relative;
    height: auto;
  }
</style>