<template>
  <component :is="componentLoader" v-bind="props"></component>
</template>
<script>
  export default {
    name: 'dialog-manager',

    props: {
      dialog: {
        type: String,
        default: () => { return null }
      },
      props: {
        type: Object,
        default: () => ({})
      }
    },

    watch: {
      dialog(val) {
        val && this.loadComponent()
      }
    },

    computed: {
      componentLoader () {
        return (this.dialog) 
          ? () => import(`@/components/_dialogs/${this.dialog}/${this.dialog}`)
          : () => {}
      }
    },

    methods: {
      loadComponent() {
        this.componentLoader().then()
      }
    }
  }
</script>

<style lang="stylus">
  .dialog {
    .card__title {
      padding-bottom: 0;
    }
  }
</style>
