<template>
  <div class="search-input"
    align-center 
    @keyup.esc="handleClearSearch">
    <v-text-field
      type="search"
      name="search"
      label="Find a program, project, feature or person"
      class="search-input__input"
      v-model="innerValue"
      ref="input"
      single-line
      append-icon="search"
      :append-icon-cb="handleSearch"
      @keyup.enter="handleSearch"/>

    <v-btn class="search-input__close"
      color="secondary" 
      depressed 
      round 
      small
      v-if="searchMode"
      @click="handleClearSearch">
      Cancel Search
    </v-btn>
  </div>
</template>

<script>
  import debounce from 'lodash.debounce'
  import DisplayManager from '@/mixins/DisplayManager'
  import { mapActions, mapState } from 'vuex'

  const SEARCH_DEBOUNCE = 3500

  export default {
    mixins: [DisplayManager],

    watch: {
      searchText(val) {
        this.innerValue = val
      },
      innerValue(val) {
        this.searchDebounce(val)
      }
    },

    data() {
      return {
        searchDebounce: debounce(this.initiateSearch, SEARCH_DEBOUNCE),
        innerValue: this.$route.params.searchText || ''
      }
    },

    computed: {
      ...mapState({
        searchText: state => state.ui.searchText
      })
    },

    methods: {
      ...mapActions(['initiateSearch', 'clearSearch']),
      handleClearSearch() {
        this.clearSearch()
        this.$emit('clear-search')
      },
      handleSearch() {
        this.searchDebounce.cancel()
        this.initiateSearch(this.innerValue)
        this.$emit('initiate-search', this.innerValue)
      }
    },
  }
</script>

<style lang="stylus">
  // @import "@/stylus/variables";
  
  .search-input {
    display: flex;
    align-items: center;
    width: 100%;
    border: 1px solid $color-grey;
    border-radius: 30px;
    padding: 5px 25px;
    position: relative;
    
    .input-group {
      padding: 0 !important;
      
      label {
        top: auto !important;
      }
    }
    
    .input-group__details {
      display: none !important;
    }
    
    .icon {
      cursor: pointer;
    }
  }
  
  .search-input__close {
    margin: 0;
    position: absolute;
    right: 0px;
    
    &.icon {
      right: 15px;
    }
    
    &.btn {
      right: 5px;
    }

    .btn__content {
      font-size: 12px;
    }
  }
</style>