import mapsApi, { negotiateResponse } from '@/api/maps'
import reduce from 'lodash.reduce'
import clonedeep from 'lodash.clonedeep'
import moment from 'moment'
import { query as esriQuery } from 'esri-leaflet'
import { 
  SUSTAINABILITY_MAP_SERVER_URL,
  SUSTAINABILITY_EVENTS_LAYER
} from '@/utils/constants'

export function normalizeEventsCollection(collection = [], fullResult) {
  try {
    let result = []
    let innerCollection = clonedeep(collection)

    let entities = reduce(innerCollection, (outcome, feature) => {
      let {
        properties,
        properties: {
          Event_calendar_date,
          Event_Date,
          Field_off_campus_location,
          Field_room,
          ID,
          Title,
          OBJECTID
        } = {}
      } = feature

      if (!ID) console.error('[normalizeEventsCollection]: Event item in collection did not contain ID')

      const EventID = String(ID || OBJECTID)

      // normalizing the location string since response is inconsistent
      const getLocation = () => {
        if (!Field_off_campus_location) return  ''

        return `${Field_off_campus_location} ${(Field_room) ? `- ${Field_room}` : ''}`
      }

      feature.properties = {
        ...properties,
        Field_off_campus_location: getLocation(),
        Event_calendar_date: moment(Event_calendar_date, 'M/DD/YYYY - h:mma').format(),
        SortDate: moment.utc(Event_Date).format(),
        isEvent: true,
        Name: Title,
        EventID,
        Status: 'active',
        SustainabilityType: 'Events'
      }

      outcome[EventID] = Object.assign({}, feature)
      result.push(EventID)

      return outcome
    }, {})

    return { result, entities }
  } catch(e) {
    throw new Error(`normalizeEventsCollection: ${e}`)
  }
}

const newsApi = {
  find({ service, text, fields = '*', layers }) {
    return new Promise((resolve, reject) => {
      mapsApi.find({ service, text, fields, layers })
        .then(({ features, results }) => resolve(normalizeEventsCollection(features, results)))
        .catch(reject)
    })
  },

  get(id) {
    if (!id) throw new Error('[api] event get: no id parameter')
    let query = esriQuery({ url: `${SUSTAINABILITY_MAP_SERVER_URL}/${SUSTAINABILITY_EVENTS_LAYER}` })

    return new Promise((resolve, reject) => {
      query.where(`ID=${id}`).run((error, featureCollection, response) => {
        negotiateResponse(error, featureCollection, response)
          .then(({ features, results }) => resolve(normalizeEventsCollection(features, results)))
          .catch(reject)
      })
    })
  }
}

export default newsApi
