// features 
export const FEATURES_UPDATE = 'FEATURES_UPDATE'
export const FEATURES_UPDATE_RELATED_PEOPLE = 'FEATURES_UPDATE_RELATED_PEOPLE'

// people
export const PEOPLE_UPDATE = 'PEOPLE_UPDATE'
export const PEOPLE_UPDATE_RELATED_ACTIVITIES = 'PEOPLE_UPDATE_RELATED_ACTIVITIES'

// maps
export const MAP_FILTER_VISIBILITY_UPDATE = 'MAP_FILTER_VISIBILITY_UPDATE'
export const MAP_THEME_VISIBILITY_UPDATE = 'MAP_THEME_VISIBILITY_UPDATE'
export const MAP_INITIAL_VISIBLE_LAYERS = 'MAP_INITIAL_VISIBLE_LAYERS'
export const MAP_FILTERS_UPDATE = 'MAP_FILTERS_UPDATE'
export const MAP_THEMES_UPDATE = 'MAP_THEMES_UPDATE'
export const MAP_SEARCH_UPDATE = 'MAP_SEARCH_UPDATE'
export const MAP_SEARCH_CLEAR = 'MAP_SEARCH_CLEAR'
export const MAP_UPDATE_ACTIVE_FEATURE = 'MAP_UPDATE_ACTIVE_FEATURE'

// news
export const NEWS_UPDATE = 'NEWS_UPDATE'

// events
export const EVENTS_UPDATE = 'EVENTS_UPDATE'

// measure
export const MEASUREMENT_MODE_UPDATE = 'MEASUREMENT_MODE_UPDATE'
export const MEASUREMENT_DATA_UPDATE = 'MEASUREMENT_DATA_UPDATE'
export const MEASUREMENT_UNIT_UPDATE = 'MEASUREMENT_UNIT_UPDATE'

// ui 
export const UI_ADD_LOADING_STATE = 'UI_ADD_LOADING_STATE'
export const UI_REMOVE_LOADING_STATE = 'UI_REMOVE_LOADING_STATE'
export const UI_UPDATE_SEARCH_TEXT = 'UI_UPDATE_SEARCH_TEXT'
export const UI_UPDATE_MAIN_SIDEBAR_OPEN = 'UI_UPDATE_MAIN_SIDEBAR_OPEN'
export const UI_UPDATE_MASTER_LIST_SIDEBAR_OPEN = 'UI_UPDATE_MASTER_LIST_SIDEBAR_OPEN'
export const UI_UPDATE_EMBED = 'UI_UPDATE_EMBED'
export const UI_UPDATE_DETAILS_SIDEBAR_OPEN = 'UI_UPDATE_DETAILS_SIDEBAR_OPEN'
export const UI_SHALLOW_HISTORY_UDATE = 'UI_SHALLOW_HISTORY_UDATE'

// dialogs
export const DIALOG_ACTIVE_UPDATE = 'DIALOG_ACTIVE_UPDATE' 
export const DIALOG_ACTIVE_CLEAR = 'DIALOG_ACTIVE_CLEAR' 
