let ListItemAvatar = {
  name: 'list-item-avatar',
  functional: true,
  props: {
    circle: Boolean
  },
  render(createEl, { data, props, children }) {
    data.class = { 
      'list-item__avatar': true,
      'list-item__avatar--circle': props.circle
    }

    return createEl('div', data, children)
  }
}

export default ListItemAvatar