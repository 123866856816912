<template>
  <v-app :class="getClassNames" v-resize="handleResize">
    <app-header/>
    <router-view></router-view>
    <dialog-manager :dialog="dialogType" :props="dialogProps"/>
    <v-snackbar
      v-model="showRefresh"
      top
      multi-line
      :timeout="0"
      color="accent">
      A new version is available! Lucky you. Click refresh to update.

      <v-btn color="white" flat @click="handleRefresh" :loading="refreshing">Refresh</v-btn>
    </v-snackbar>
  </v-app>
</template>

<script>
  import AppHeader from '@/components/AppHeader/AppHeader'
  import DialogManager from '@/components/_dialogs/DialogManager'
  import { ServiceWorkerBus } from '@/buses'
  import { mapState } from 'vuex'

  export default {
    components: {
      AppHeader,
      DialogManager
    },

    mounted() {
      ServiceWorkerBus.$on('show-refresh', registration => {
        // adding it to a non-observed instance property
        this.$registration = registration
        this.showRefresh = true
      })

      // a hack to fix viewport height issues implement by some mobile browsers
      // https://developers.google.com/web/updates/2016/12/url-bar-resizing
      // https://css-tricks.com/the-trick-to-viewport-units-on-mobile/
      this.setDocumentElementStyleProp()
    },

    data() {
      return {
        showRefresh: false,
        refreshing: false
      }
    },

    computed: {
      ...mapState({
        dialogType: state => state.dialogs.dialog,
        dialogProps: state => state.dialogs.props,
        embed: state => state.ui.embed
      }),
      getClassNames() {
        return {
          'sustainability-map': true,
          'app--embed': !!this.embed
        }
      }
    },

    methods: {
      handleClose() {
        this.showRefresh = false
      },
      handleRefresh() {
        this.showRefresh = false

        if (this.$registration) {
          // Just to ensure registration.waiting is available before
          // calling postMessage()
          if (!this.$registration.waiting) return

          this.refreshing = true

          this.$registration.waiting.postMessage('skipWaiting')
        }
      },
      handleResize() {
        this.setDocumentElementStyleProp()
      },
      setDocumentElementStyleProp() {
        // a hack to fix viewport height issues implement by some mobile browsers
        // https://developers.google.com/web/updates/2016/12/url-bar-resizing
        // https://css-tricks.com/the-trick-to-viewport-units-on-mobile/
        window.document.documentElement.style.setProperty('--vh', `${window.innerHeight * 0.01}px`)
      }
    }
  }
</script>

<style lang="stylus">
  @import './stylus/main'
  
  .sustainability-map
    .snack__content
      color: #fff
      font-weight: bold

  .info-panel-anim-enter-active, .info-panel-anim-leave-active {
    transition: opacity .45s ease;
  }
  
  .info-panel-anim-enter, .info-panel-anim-leave-active {
    opacity: 1
  }

  .application--wrap {
    height: 100vh !important
    min-height: 100vh !important
    height: calc(var(--vh, 1vh) * 100) !important
    min-height: calc(var(--vh, 1vh) * 100) !important
  }
</style>
