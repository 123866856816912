<template>
  <v-navigation-drawer
    v-model="Mixin_active" 
    app 
    clipped
    hide-overlay
    stateless
    temporary
    :mini-variant="mini"
    touchless
    :mini-variant-width="$vuetify.breakpoint.xsOnly ? 50 : 70"
    :width="mainSideBarWidth"
    class="themes-sidebar elevation-3"
    v-resize="handleResize"
  >
    <v-list class="theme-list" role="navigation" aria-label="Sustainability Theme Menu">
      <div
        @click="mini = !mini"
        class="theme-list__mini-trigger" role="button"
      >
        <span class="theme-list__item-label">Select Themes</span>

        <div class="theme-list__mini-trigger__actions">
          <v-btn
            v-if="!mini"
            icon
          >
            <v-icon>chevron_left</v-icon>
          </v-btn>
          <v-btn
            v-else
            icon
          >
            <v-icon>chevron_right</v-icon>
          </v-btn>
        </div>
      </div>

      <v-list-tile v-for="(themeId, index) in themeOrder"
        :key="index"
        @click.prevent="toggleTheme(themeId)"
        :class="themeListClass(themeId)"
        tag="li">
        <v-list-tile-avatar>
          <icon-moon class="theme-list__item-icon" :name="getThemeSlug(themeId)"/>
        </v-list-tile-avatar>
        
        <v-list-tile-content>
          <v-list-tile-title class="theme-list__item-label">{{ getThemeName(themeId) }}</v-list-tile-title>
        </v-list-tile-content>

        <v-list-tile-action v-if="!mini">
          <v-switch v-model="visibleThemes" :value="themeId" class="theme-list__item-switch" dark/>
        </v-list-tile-action>
      </v-list-tile>

      <template v-if="!mini">
        <v-layout justify-center class="themes-sidebar__selection-group py-3">
          <v-btn round outline @click="massToggleThemes(false)">Clear All</v-btn>
          <v-btn round outline @click="massToggleThemes(true)">Select All</v-btn>
        </v-layout>
      </template>
    </v-list>

    <div class="themes-sidebar__search-overlay" v-if="searchMode">
      <template v-if="!mini">
        <h4 class="hidden-sm-and-down">You must exit your search before toggling theme layers.</h4>
        <v-btn round @click="clearSearch" class="hidden-sm-and-down">Cancel Search</v-btn>
      </template>
    </div>

    <map-controls/>
  </v-navigation-drawer>
</template>

<script>
  import MapControls from '@/components/MapControls/MapControls'
  import { mapActions, mapState, mapGetters, mapMutations } from 'vuex'
  import { MAP_THEME_VISIBILITY_UPDATE } from '@/store/mutation-types'
  import { UI_UPDATE_MAIN_SIDEBAR_OPEN } from '@/store/mutation-types'
  import DisplayManager from '@/mixins/DisplayManager'
  import ExternalBind from '@/mixins/ExternalBind' 
  import reduce from 'lodash.reduce'
  import forEach from 'lodash.foreach'

  let ThemesSidebar = {
    components: {
      MapControls
    },

    mixins: [ExternalBind, DisplayManager],

    watch: {
      searchMode(val) {
        if (val) this.mini = true

        // open sidebar back up if on desktop
        if (!val && this.$vuetify.breakpoint.mdAndUp) this.mini = false
      }
    },

    mounted() {
      this.updateMainSideBar(this.Mixin_active)
      this.handleResize()
    },

    data() {
      return {
        mini: false,
        miniWidth: 70
      }
    },

    computed: {
      ...mapState({
        themeOrder: state => state.maps.ThemeOrder,
        mainSideBarWidth: state => state.ui.mainSideBarWidth
      }),
      ...mapGetters(['getThemeById', 'getVisibleThemes', 'isThemeVisible']),
      visibleThemes: {
        get() { return this.getVisibleThemes },
        set(val) { /* do nothing */ }
      }
    },

    methods: {
      ...mapMutations({
        setThemeVisibility: MAP_THEME_VISIBILITY_UPDATE,
        updateMainSideBar: UI_UPDATE_MAIN_SIDEBAR_OPEN
      }),
      ...mapActions(['clearSearch', 'toggleTheme']),
      massToggleThemes(visible) {
        forEach(this.themeOrder, (themeId) => {
          this.setThemeVisibility({
            themeId,
            visible
          })
        })
      },
      getThemeName(themeId) {
        return this.getThemeById(themeId).name
      },
      getThemeSlug(themeId) {
        return this.getThemeById(themeId).slug
      },
      getThemeColor(themeId) {
        return this.getThemeById(themeId).color.toLowerCase()
      },
      themeListClass(themeId) {
        return {
          'theme-list__item': true,
          [`theme-list__item--${this.getThemeColor(themeId)}`]: true,
          [`theme-list__item--off`]: !this.isThemeVisible(themeId),
        }
      },
      handleResize(e) {
        if (
          !e ||
          (
            e &&
            e.constructor &&
            e.constructor.name === 'Event'
          )
        ) {
          this.mini = window.innerWidth <= 1000
        }
      }
    }
  }

  export default ThemesSidebar
</script>

<style lang="stylus">
  // @import "@/stylus/variables"

  $mini-trigger-height = 60px;
  $mini-trigger-height-mobile = 40px;

  .themes-sidebar {
    box-shadow: 0px 0px 6px 3px rgba(#000, 0.2);
    overflow: visible !important;
    padding-bottom: 0;
    z-index: 3 !important;
    background-color: $common-background !important;
  }

  .themes-sidebar.navigation-drawer--close {
    box-shadow: none;
  }

  .themes-sidebar__search-overlay {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background-color: rgba($color-dark-grey, 0.85);
    padding: 15px;
    text-align: center;
    color: #fff;
    display: flex;
    flex-direction: column;
    justify-content: center;

    h4 {
      color: inherit !important;
    }
  }

  .themes-sidebar__selection-group {
    background-color: $common-background;

    .btn--round:first-child {
      border-top-right-radius: 0px;
      border-bottom-right-radius: 0px;
      margin-right: 0px;
    }

    .btn--round:last-child {
      border-top-left-radius: 0px;
      border-bottom-left-radius: 0px;
      margin-left: -1px;
    }
  }

  .theme-list {
    overflow: auto;
    height: 100%;
    -webkit-overflow-scrolling: touch;
    padding-top: $mini-trigger-height !important;
    position: relative;

    .theme-list__mini-trigger {
      width: 100%;
      position: fixed;
      top: 0;
      z-index: 1;
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 0 15px;
      height: $mini-trigger-height;
      background-color: $common-background;

      .theme-list__item-label {
        text-transform: uppercase;
        color: $color-grey;
      }

      .theme-list__mini-trigger__actions {
        .btn--icon { margin: 0; }
      }

      .list__tile--link {
        display: flex;
        justify-content: space-between;

        &:hover {
          background-color: transparent !important;
        }
      }
    }

    .theme-list__item {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 6px 15px;
      cursor: pointer;
      transition: background-color .25s ease,
                  opacity .25s ease;
      
      .list__tile--link {
        padding: 0;
        width: 100%;

        &:hover {
          background-color: transparent !important;
        }

        .list__tile__avatar, .avatar {
          min-width: auto !important;
          margin-right: 5px;
        }
      }

      .list__tile__action {
        min-width: auto;
      }

      .theme-list__item-switch {
        flex: auto !important;
      }

      .input-group--selection-controls__ripple:after {
        background-color: $color-light-grey !important;
      }

      .theme-list__item-label {
        color: $color-light-grey;
        font-size: 17px;
        font-weight: bold;
        text-transform: uppercase;
      }

      .theme-list__item-label-group {
        display: flex;
        align-items: center;
      }

      .input-group__details {
        display: none;
      }
    }

    .theme-list__item--off {
      opacity: 0.5;
    }

    .theme-list__item--green {
      background-color: $color-green;

      &:hover {
        background-color: darken($color-green, 10%);
      }
    }

    .theme-list__item--teal {
      background-color: $color-teal;

      &:hover {
        background-color: darken($color-teal, 10%);
      }
    }

    .theme-list__item--purple {
      background-color: $color-purple;

      &:hover {
        background-color: darken($color-purple, 10%);
      }
    }

    .theme-list__item--yellow {
      background-color: $color-yellow;

      &:hover {
        background-color: darken($color-yellow, 10%);
      }
    }

    .theme-list__item--orange {
      background-color: $color-orange;

      &:hover {
        background-color: darken($color-orange, 10%);
      }
    }

    .theme-list__item--brown {
      background-color: $color-brown;

      &:hover {
        background-color: darken($color-brown, 10%);
      }
    }

    .theme-list__item--dark-grey {
      background-color: $color-dark-grey;

      &:hover {
        background-color: darken($color-dark-grey, 10%);
      }
    }

    .theme-list__item--blue {
      background-color: $color-blue;

      &:hover {
        background-color: darken($color-blue, 10%);
      }
    }
  }

  .navigation-drawer--mini-variant {
    .theme-list__item {
      padding: 6px 15px;
    }

    .theme-list__mini-trigger {
      justify-content: center;

      .theme-list__item-label {
        display: none;
      }
    }
  }

  @media(max-width: $xs-screen-breakpoint) {
    .theme-list {
      padding-top: $mini-trigger-height-mobile !important;

      .theme-list__mini-trigger {
        height: $mini-trigger-height-mobile;
      }

      .theme-list__item {
        padding: 3px 8px;

        .list__tile {
          height: $mini-trigger-height-mobile;
        }

        .theme-list__item-icon {
          font-size: 30px !important;
        }

        .theme-list__item-label {
          font-size: 16px;
        }
      }
    }

    .navigation-drawer--mini-variant {
      .theme-list__item {
        padding: 3px 8px;

        .list__tile__avatar, .avatar {
          width: auto !important;
          margin-right: 0;
        }
      }
    }
  }
</style>
